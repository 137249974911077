import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Account/Balances/Usdc/Header.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Account/Balances/Usdc/Row.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Account/Balances/Row/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { BigNumberInBase } from "@injectivelabs/utils";
import { usdcTokenDenom, usdcTokenDenoms } from "@/app/data/token";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    hideBalances: Boolean,
    balances: {
      type: Array,
      required: true
    },
    aggregatedBalance: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const showUsdcBalances = ref(true);
    const usdcBalances = computed(() => {
      return props.balances.filter(
        (balance) => usdcTokenDenoms.includes(balance.token.denom.toLowerCase())
      );
    });
    const peggyUsdcetBalance = computed(() => {
      return props.balances.find(
        (balance) => balance.denom.toLowerCase() === usdcTokenDenom.USDCet.toLowerCase()
      );
    });
    const hasPeggyUsdcBalance = computed(() => {
      const balance = props.balances.find(
        (balance2) => balance2.denom.toLowerCase() === usdcTokenDenom.USDC.toLowerCase()
      );
      return new BigNumberInBase((balance == null ? void 0 : balance.totalBalance) || 0).gt(0);
    });
    function toggleUsdcBalances() {
      showUsdcBalances.value = !showUsdcBalances.value;
    }
    return (_ctx, _cache) => {
      const _component_PartialsAccountBalancesUsdcHeader = __nuxt_component_0;
      const _component_PartialsAccountBalancesUsdcRow = __nuxt_component_1;
      const _component_PartialsAccountBalancesRow = __nuxt_component_2;
      return _openBlock(), _createElementBlock("tbody", null, [
        _unref(hasPeggyUsdcBalance) ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_PartialsAccountBalancesUsdcHeader, _mergeProps({
            ..._ctx.$attrs,
            hideBalances: __props.hideBalances,
            showUsdcBalances: _unref(showUsdcBalances),
            aggregatedBalance: __props.aggregatedBalance
          }, { "onDrawer:toggle": toggleUsdcBalances }), null, 16),
          _unref(showUsdcBalances) ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(usdcBalances), (usdcBalance, index) => {
            return _openBlock(), _createBlock(_component_PartialsAccountBalancesUsdcRow, _mergeProps({
              key: usdcBalance.token.denom,
              class: {
                "border-b-transparent": index < _unref(usdcBalances).length - 1
              }
            }, {
              ..._ctx.$attrs,
              hideBalances: __props.hideBalances,
              hasPeggyUsdcBalance: _unref(hasPeggyUsdcBalance),
              isOpen: _unref(showUsdcBalances),
              balance: usdcBalance,
              isHoldingSingleUsdcDenom: _unref(usdcBalances).length === 1
            }), null, 16, ["class"]);
          }), 128)) : _createCommentVNode("", true)
        ], 64)) : _unref(peggyUsdcetBalance) ? (_openBlock(), _createBlock(_component_PartialsAccountBalancesRow, _mergeProps({
          key: _unref(peggyUsdcetBalance).denom
        }, {
          ..._ctx.$attrs,
          hideBalances: __props.hideBalances,
          balance: _unref(peggyUsdcetBalance)
        }), null, 16)) : _createCommentVNode("", true)
      ]);
    };
  }
});

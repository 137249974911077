"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { Status, StatusType } from "@injectivelabs/utils";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    hideBalances: Boolean,
    balance: {
      type: Object,
      required: true
    },
    usdPriceStatus: {
      type: Object,
      default: new Status(StatusType.Loading)
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_PartialsAccountBalancesRowWrapper = _resolveComponent("PartialsAccountBalancesRowWrapper");
      return _openBlock(), _createBlock(_component_PartialsAccountBalancesRowWrapper, _normalizeProps(_guardReactiveProps({
        balance: __props.balance,
        hideBalances: __props.hideBalances,
        usdPriceStatus: __props.usdPriceStatus
      })), null, 16);
    };
  }
});

import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/SortableHeaderItem.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/InfoTooltip.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/HeaderItem.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "h-14" };
const _hoisted_2 = { class: "text-gray-350 text-xs normal-case" };
const _hoisted_3 = { class: "text-gray-350 text-xs normal-case" };
const _hoisted_4 = { class: "text-gray-350 text-xs normal-case" };
const _hoisted_5 = { class: "text-gray-350 text-xs normal-case" };
const _hoisted_6 = { class: "text-gray-350 text-xs normal-case" };
const _hoisted_7 = { class: "text-gray-350 text-xs normal-case" };
const _hoisted_8 = /* @__PURE__ */ _createElementVNode("th", { class: "pr-4" }, null, -1);
import { BalanceHeaderType } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Header",
  props: {
    ascending: Boolean,
    sortBy: {
      type: String,
      required: true
    }
  },
  emits: ["update:sort-by", "update:ascending"],
  setup(__props, { emit }) {
    const props = __props;
    const sortByValue = computed({
      get: () => props.sortBy,
      set: (type) => {
        emit("update:sort-by", type);
      }
    });
    const ascendingValue = computed({
      get: () => props.ascending,
      set: (type) => {
        emit("update:ascending", type);
      }
    });
    return (_ctx, _cache) => {
      const _component_AppSortableHeaderItem = __nuxt_component_0;
      const _component_CommonInfoTooltip = __nuxt_component_1;
      const _component_AppHeaderItem = __nuxt_component_2;
      return _openBlock(), _createElementBlock("tr", _hoisted_1, [
        _createVNode(_component_AppSortableHeaderItem, {
          "sort-by": _unref(sortByValue),
          "onUpdate:sortBy": _cache[0] || (_cache[0] = ($event) => _isRef(sortByValue) ? sortByValue.value = $event : null),
          ascending: _unref(ascendingValue),
          "onUpdate:ascending": _cache[1] || (_cache[1] = ($event) => _isRef(ascendingValue) ? ascendingValue.value = $event : null),
          value: _unref(BalanceHeaderType).Asset,
          class: "pl-2"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("account.balances.cols.asset")), 1)
          ]),
          _: 1
        }, 8, ["sort-by", "ascending", "value"]),
        _createVNode(_component_AppSortableHeaderItem, {
          "sort-by": _unref(sortByValue),
          "onUpdate:sortBy": _cache[2] || (_cache[2] = ($event) => _isRef(sortByValue) ? sortByValue.value = $event : null),
          ascending: _unref(ascendingValue),
          "onUpdate:ascending": _cache[3] || (_cache[3] = ($event) => _isRef(ascendingValue) ? ascendingValue.value = $event : null),
          class: "justify-end",
          value: _unref(BalanceHeaderType).Available
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("account.balances.cols.availableBalance")), 1)
          ]),
          _: 1
        }, 8, ["sort-by", "ascending", "value"]),
        _createVNode(_component_AppHeaderItem, { class: "justify-end" }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("account.balances.cols.inUseReserved")), 1),
            _createVNode(_component_CommonInfoTooltip, {
              class: "text-gray-200",
              tooltip: _ctx.$t("account.balances.inUseReservedTooltip")
            }, null, 8, ["tooltip"])
          ]),
          _: 1
        }),
        _createVNode(_component_AppHeaderItem, { class: "justify-end" }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("account.balances.cols.unrealized")), 1),
            _createVNode(_component_CommonInfoTooltip, {
              class: "text-gray-200",
              tooltip: _ctx.$t("account.balances.unrealizedTooltip")
            }, null, 8, ["tooltip"])
          ]),
          _: 1
        }),
        _createVNode(_component_AppSortableHeaderItem, {
          "sort-by": _unref(sortByValue),
          "onUpdate:sortBy": _cache[4] || (_cache[4] = ($event) => _isRef(sortByValue) ? sortByValue.value = $event : null),
          ascending: _unref(ascendingValue),
          "onUpdate:ascending": _cache[5] || (_cache[5] = ($event) => _isRef(ascendingValue) ? ascendingValue.value = $event : null),
          class: "justify-end",
          value: _unref(BalanceHeaderType).Total
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("account.balances.cols.totalBalance")), 1)
          ]),
          _: 1
        }, 8, ["sort-by", "ascending", "value"]),
        _createVNode(_component_AppSortableHeaderItem, {
          "sort-by": _unref(sortByValue),
          "onUpdate:sortBy": _cache[6] || (_cache[6] = ($event) => _isRef(sortByValue) ? sortByValue.value = $event : null),
          ascending: _unref(ascendingValue),
          "onUpdate:ascending": _cache[7] || (_cache[7] = ($event) => _isRef(ascendingValue) ? ascendingValue.value = $event : null),
          class: "select-none justify-end",
          value: _unref(BalanceHeaderType).Value
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("account.balances.cols.value", { symbol: "USD" })), 1)
          ]),
          _: 1
        }, 8, ["sort-by", "ascending", "value"]),
        _hoisted_8
      ]);
    };
  }
});

import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Account/Balances/AssetDetails/MarketCard.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/App/HocLoading.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "fixed inset-0 lg:top-14 bg-gray-900 w-full z-40"
};
const _hoisted_2 = { class: "container py-6 h-full min-h-screen-excluding-header flex flex-col" };
const _hoisted_3 = { class: "w-full mx-auto 3xl:w-11/12 4xl:w-10/12 flex flex-col h-full flex-grow" };
const _hoisted_4 = { class: "flex items-center justify-start gap-2" };
const _hoisted_5 = { class: "font-bold text-sm" };
const _hoisted_6 = { class: "mt-6 border-b border-gray-600" };
const _hoisted_7 = {
  key: 0,
  class: "flex items-center justify-start gap-2"
};
const _hoisted_8 = { class: "tracking-wide font-bold text-sm" };
const _hoisted_9 = { class: "text-gray-450 text-xs" };
const _hoisted_10 = { class: "flex flex-col gap-4 py-4" };
const _hoisted_11 = { class: "flex justify-between items-center" };
const _hoisted_12 = { class: "text-gray-350 text-sm" };
const _hoisted_13 = { class: "font-mono text-sm tracking-wide" };
const _hoisted_14 = { class: "flex justify-between items-center" };
const _hoisted_15 = { class: "text-gray-350 text-sm" };
const _hoisted_16 = { class: "font-mono text-sm tracking-wide" };
const _hoisted_17 = { class: "flex justify-between items-center" };
const _hoisted_18 = { class: "text-gray-350 text-sm" };
const _hoisted_19 = { class: "font-mono text-sm tracking-wide" };
const _hoisted_20 = { class: "flex justify-between items-center" };
const _hoisted_21 = { class: "text-gray-350 text-sm" };
const _hoisted_22 = { class: "font-mono text-sm tracking-wide" };
const _hoisted_23 = { class: "py-6 h-full overflow-hidden flex flex-col gap-4" };
const _hoisted_24 = { class: "font-bold text-sm" };
const _hoisted_25 = {
  key: 0,
  class: "h-full overflow-marketsWithSummary-auto"
};
const _hoisted_26 = { class: "grid grid-cols-2 gap-4" };
const _hoisted_27 = {
  key: 1,
  class: "mt-4"
};
const _hoisted_28 = { class: "text-sm" };
const _hoisted_29 = { class: "mt-auto flex justify-between gap-4" };
const _hoisted_30 = { class: "text-sm font-medium" };
const _hoisted_31 = { class: "text-sm font-medium text-blue-500" };
import { Status, StatusType, BigNumberInBase } from "@injectivelabs/utils";
import { BusEvents, Modal, BridgeBusEvents } from "@/types";
import {
  UI_DEFAULT_DISPLAY_DECIMALS,
  UI_DEFAULT_MIN_DISPLAY_DECIMALS
} from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  setup(__props) {
    const modalStore = useModalStore();
    const spotStore = useSpotStore();
    const status = reactive(new Status(StatusType.Idle));
    const scrollOffset = ref(0);
    const accountBalance = ref(void 0);
    const isModalOpen = computed(
      () => modalStore.modals[Modal.AssetDetails] && !!accountBalance.value
    );
    const filteredMarketsWithSummary = computed(() => {
      return spotStore.marketsWithSummary.filter(({ market }) => {
        if (!accountBalance.value) {
          return false;
        }
        return market.baseDenom === accountBalance.value.token.denom || market.quoteDenom === accountBalance.value.token.denom;
      });
    });
    const availableMargin = computed(() => {
      var _a;
      return new BigNumberInBase(((_a = accountBalance.value) == null ? void 0 : _a.availableMargin) || 0);
    });
    const { valueToString: availableMarginToString } = useBigNumberFormatter(
      availableMargin,
      {
        decimalPlaces: UI_DEFAULT_DISPLAY_DECIMALS
      }
    );
    const { valueToString: inOrderBalanceToString } = useBigNumberFormatter(
      computed(() => {
        var _a;
        return ((_a = accountBalance.value) == null ? void 0 : _a.inOrderBalance) || "0";
      }),
      {
        decimalPlaces: UI_DEFAULT_DISPLAY_DECIMALS
      }
    );
    const { valueToString: accountTotalBalanceToString } = useBigNumberFormatter(
      computed(() => {
        var _a;
        return ((_a = accountBalance.value) == null ? void 0 : _a.accountTotalBalance) || "0";
      }),
      {
        decimalPlaces: UI_DEFAULT_DISPLAY_DECIMALS
      }
    );
    const { valueToString: accountTotalBalanceInUsdToString } = useBigNumberFormatter(
      computed(() => {
        var _a;
        return ((_a = accountBalance.value) == null ? void 0 : _a.accountTotalBalanceInUsd) || "0";
      }),
      {
        decimalPlaces: UI_DEFAULT_MIN_DISPLAY_DECIMALS
      }
    );
    onMounted(() => {
      useEventBus(BusEvents.AssetDetailsModalPayload).on(
        (accountBalancePayload) => {
          accountBalance.value = accountBalancePayload;
        }
      );
    });
    watch(
      () => isModalOpen.value,
      (value) => {
        if (value) {
          return handleOpen();
        }
        handleClosed();
      }
    );
    function handleOpen() {
      scrollOffset.value = window.pageYOffset;
      window.scrollTo(0, 0);
    }
    function handleClosed() {
      window.scrollTo(0, scrollOffset.value);
    }
    function handleClose() {
      modalStore.closeModal(Modal.AssetDetails);
      accountBalance.value = void 0;
    }
    function handleDepositClick() {
      if (!accountBalance.value) {
        return;
      }
      useEventBus(BridgeBusEvents.Deposit).emit(
        accountBalance.value.token
      );
      handleClose();
    }
    function handleWithdrawClick() {
      if (!accountBalance.value) {
        return;
      }
      useEventBus(BridgeBusEvents.Withdraw).emit(
        accountBalance.value.token
      );
      handleClose();
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_CommonTokenIcon = __nuxt_component_1;
      const _component_PartialsAccountBalancesAssetDetailsMarketCard = __nuxt_component_2;
      const _component_NuxtLink = __nuxt_component_3;
      const _component_AppHocLoading = __nuxt_component_4;
      return _unref(isModalOpen) && _unref(accountBalance) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_AppHocLoading, {
          status: _unref(status),
          class: "h-full"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", {
                    class: "cursor-pointer",
                    onClick: handleClose
                  }, [
                    _createVNode(_component_BaseIcon, {
                      name: "arrow",
                      class: "w-4 h-4 text-white"
                    })
                  ]),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("account.assetDetails.title")), 1)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _unref(accountBalance) ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _unref(accountBalance) && _unref(accountBalance).token ? (_openBlock(), _createBlock(_component_CommonTokenIcon, {
                      key: 0,
                      token: _unref(accountBalance).token,
                      sm: ""
                    }, null, 8, ["token"])) : _createCommentVNode("", true),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(accountBalance).token.symbol), 1),
                    _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(accountBalance).token.name), 1)
                  ])) : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("account.balances.cols.availableBalance")), 1),
                      _createElementVNode("span", _hoisted_13, _toDisplayString(_unref(availableMarginToString)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("account.balances.cols.inUseReserved")), 1),
                      _createElementVNode("span", _hoisted_16, _toDisplayString(_unref(inOrderBalanceToString)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t("account.balances.cols.totalBalance")), 1),
                      _createElementVNode("span", _hoisted_19, _toDisplayString(_unref(accountTotalBalanceToString)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("account.balances.cols.value", { symbol: "USD" })), 1),
                      _createElementVNode("span", _hoisted_22, _toDisplayString(_unref(accountTotalBalanceInUsdToString)) + " USD ", 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t("account.assetDetails.trade")), 1),
                  _unref(filteredMarketsWithSummary).length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredMarketsWithSummary), ({ market, summary }) => {
                        return _openBlock(), _createBlock(_component_NuxtLink, {
                          key: market.slug,
                          to: {
                            name: "spot-spot",
                            params: { spot: market.slug }
                          }
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_PartialsAccountBalancesAssetDetailsMarketCard, {
                              market,
                              summary
                            }, null, 8, ["market", "summary"])
                          ]),
                          _: 2
                        }, 1032, ["to"]);
                      }), 128))
                    ])
                  ])) : (_openBlock(), _createElementBlock("div", _hoisted_27, [
                    _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$t("account.assetDetails.emptyMarkets")), 1)
                  ]))
                ]),
                _createElementVNode("div", _hoisted_29, [
                  _createElementVNode("button", {
                    class: "w-full cursor-pointer h-10 flex justify-center items-center rounded-lg bg-blue-500 border-transparent border",
                    onClick: handleDepositClick
                  }, [
                    _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.$t("account.deposit")), 1)
                  ]),
                  _createElementVNode("button", {
                    class: "w-full cursor-pointer h-10 flex justify-center items-center rounded-lg bg-transparent border-blue-500 border",
                    onClick: handleWithdrawClick
                  }, [
                    _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.$t("account.withdraw")), 1)
                  ])
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["status"])
      ])) : _createCommentVNode("", true);
    };
  }
});

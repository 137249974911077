"use strict";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "h-14" };
const _hoisted_2 = {
  class: "text-gray-350 text-xs normal-case",
  "data-cy": "account-positions-market-table-header"
};
const _hoisted_3 = {
  class: "text-gray-350 text-xs normal-case",
  "data-cy": "account-positions-side-table-header"
};
const _hoisted_4 = {
  class: "text-gray-350 text-xs normal-case",
  "data-cy": "account-positions-quantity-table-header"
};
const _hoisted_5 = {
  class: "text-gray-350 text-xs normal-case",
  "data-cy": "account-positions-entryMark-table-header"
};
const _hoisted_6 = {
  class: "text-gray-350 text-xs normal-case",
  "data-cy": "account-positions-estLiquidationPrice-table-header"
};
const _hoisted_7 = {
  class: "text-gray-350 text-xs normal-case",
  "data-cy": "account-positions-unrealizedPnl-table-header"
};
const _hoisted_8 = {
  class: "text-gray-350 text-xs normal-case",
  "data-cy": "account-positions-total-table-header"
};
const _hoisted_9 = {
  class: "text-gray-350 text-xs normal-case",
  "data-cy": "account-positions-margin-table-header"
};
const _hoisted_10 = {
  class: "text-gray-350 text-xs normal-case",
  "data-cy": "account-positions-leverage-table-header"
};
const _hoisted_11 = /* @__PURE__ */ _createElementVNode("th", { class: "pr-4" }, null, -1);
export function render(_ctx, _cache) {
  const _component_AppHeaderItem = _resolveComponent("AppHeaderItem");
  return _openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createVNode(_component_AppHeaderItem, { class: "col-span-1 justify-start pl-2" }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("account.positions.cols.market")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_AppHeaderItem, { class: "col-span-1 justify-start" }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("account.positions.cols.side")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_AppHeaderItem, { class: "col-span-1 justify-end" }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("account.positions.cols.quantity")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_AppHeaderItem, { class: "col-span-1 justify-end" }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("account.positions.cols.entryMark")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_AppHeaderItem, { class: "col-span-1 justify-end" }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("account.positions.cols.estLiquidationPrice")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_AppHeaderItem, { class: "col-span-1 justify-end" }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("account.positions.cols.unrealizedPnl")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_AppHeaderItem, { class: "col-span-1 justify-end" }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("account.positions.cols.total")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_AppHeaderItem, { class: "col-span-1 justify-end" }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("account.positions.cols.margin")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_AppHeaderItem, { class: "col-span-1 justify-end" }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("account.positions.cols.leverage")), 1)
      ]),
      _: 1
    }),
    _hoisted_11
  ]);
}

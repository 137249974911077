import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("th", _normalizeProps(_guardReactiveProps(_ctx.$attrs)), [
    _createElementVNode("div", {
      class: _normalizeClass(["flex cursor-pointer items-center gap-2", _ctx.$attrs.class])
    }, [
      _renderSlot(_ctx.$slots, "prefix"),
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 16))
}
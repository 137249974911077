import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Spinner.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Convert/SummaryRow.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "flex items-center justify-end gap-2"
};
const _hoisted_2 = { class: "text-xs text-gray-500" };
const _hoisted_3 = { class: "space-y-3 mt-2" };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { key: 1 };
import { ZERO_IN_BASE } from "@injectivelabs/sdk-ui-ts";
import { BigNumberInBase } from "@injectivelabs/utils";
import { TradeField } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Summary",
  props: {
    isBuy: Boolean,
    isLoading: Boolean,
    amount: {
      type: String,
      default: void 0
    },
    worstPriceWithSlippage: {
      type: Object,
      required: true
    },
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const formValues = useFormValues();
    const { takerFeeRate } = useTradeFee(computed(() => props.market));
    const showEmpty = computed(() => {
      return !props.market || props.worstPriceWithSlippage.eq(0) || new BigNumberInBase(props.amount || 0).isNaN();
    });
    const fee = computed(() => {
      const quantity = new BigNumberInBase(
        formValues.value[TradeField.QuoteAmount] || 0
      );
      if (quantity.isNaN() || quantity.lte(0)) {
        return ZERO_IN_BASE;
      }
      return quantity.times(takerFeeRate.value);
    });
    const { valueToString: feeToFormat } = useBigNumberFormatter(fee, {
      decimalPlaces: props.market.priceDecimals || 3,
      minimalDecimalPlaces: props.market.priceDecimals || 3
    });
    return (_ctx, _cache) => {
      const _component_AppSpinner = __nuxt_component_0;
      const _component_PartialsConvertSummaryRow = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", null, [
        __props.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_AppSpinner, { sm: "" }),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("trade.convert.fetching_price")), 1)
        ])) : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_PartialsConvertSummaryRow, {
            title: `${_ctx.$t("account.fee")}`
          }, {
            default: _withCtx(() => {
              var _a;
              return [
                _unref(showEmpty) ? (_openBlock(), _createElementBlock("span", _hoisted_4, "\u2014")) : (_openBlock(), _createElementBlock("span", _hoisted_5, "\u2248 " + _toDisplayString(_unref(feeToFormat)) + " " + _toDisplayString((_a = __props.market) == null ? void 0 : _a.quoteToken.symbol), 1))
              ];
            }),
            _: 1
          }, 8, ["title"])
        ])
      ]);
    };
  }
});

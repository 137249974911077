"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { key: 1 };
const _hoisted_3 = { key: 2 };
import { usdcTokenDenom } from "@/app/data/token";
export default /* @__PURE__ */ _defineComponent({
  __name: "Pill",
  props: {
    balance: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const isWHSolUSDTDenom = computed(
      () => props.balance.token.denom.toLowerCase() === usdcTokenDenom.USDCso
    );
    const isPeggyEthUSDTDenom = computed(
      () => props.balance.token.denom.toLowerCase() === usdcTokenDenom.USDC
    );
    const isWHEthUSDTDenom = computed(
      () => props.balance.token.denom.toLowerCase() === usdcTokenDenom.USDCet
    );
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("span", {
        class: _normalizeClass(["flex items-center justify-center rounded p-1 text-xs font-bold tracking-tight bg-gray-500 text-white", {
          "bg-gray-500": _unref(isPeggyEthUSDTDenom) || _unref(isWHSolUSDTDenom),
          "bg-blue-550": _unref(isWHEthUSDTDenom)
        }])
      }, [
        _unref(isPeggyEthUSDTDenom) ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t("account.injectiveBridge")), 1)) : _createCommentVNode("", true),
        _unref(isWHEthUSDTDenom) ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("account.wormhole")), 1)) : _createCommentVNode("", true),
        _unref(isWHSolUSDTDenom) ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("account.solana")), 1)) : _createCommentVNode("", true)
      ], 2);
    };
  }
});

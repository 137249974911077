import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Dropdown.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = { class: "text-blue-500 text-sm font-medium cursor-pointer" };
const _hoisted_2 = { class: "rounded flex items-center justify-center w-auto h-auto cursor-pointer" };
const _hoisted_3 = { class: "text-blue-500 text-sm font-medium cursor-pointer" };
const _hoisted_4 = { class: "flex flex-col py-2" };
import { getMarketRoute } from "@/app/utils/market";
export default /* @__PURE__ */ _defineComponent({
  __name: "TradeLink",
  props: {
    balance: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const spotStore = useSpotStore();
    const filteredMarkets = computed(() => {
      return spotStore.markets.filter(
        (m) => m.baseDenom === props.balance.token.denom || m.quoteDenom === props.balance.token.denom
      );
    });
    const filteredMarketsWithRoute = computed(() => {
      return filteredMarkets.value.map((market) => ({
        ...market,
        route: getMarketRoute(market)
      }));
    });
    return (_ctx, _cache) => {
      const _component_NuxtLink = __nuxt_component_0;
      const _component_BaseDropdown = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", null, [
        _unref(filteredMarketsWithRoute).length === 1 ? (_openBlock(), _createBlock(_component_NuxtLink, {
          key: 0,
          class: "rounded flex items-center justify-center w-auto h-auto cursor-pointer",
          to: _unref(filteredMarketsWithRoute)[0].route
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("account.trade")), 1)
          ]),
          _: 1
        }, 8, ["to"])) : (_openBlock(), _createBlock(_component_BaseDropdown, {
          key: 1,
          "popper-class": "rounded-lg flex flex-col flex-wrap text-xs absolute max-w-52 bg-gray-750 shadow-dropdown"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("account.trade")), 1)
            ])
          ]),
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredMarketsWithRoute), (market) => {
                return _openBlock(), _createBlock(_component_NuxtLink, {
                  key: market.slug,
                  class: "px-4 py-2 font-semibold text-sm uppercase cursor-pointer text-white hover:text-blue-500",
                  to: market.route
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(market.baseToken.symbol) + "/" + _toDisplayString(market.quoteToken.symbol), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]);
              }), 128))
            ])
          ]),
          _: 1
        }))
      ]);
    };
  }
});

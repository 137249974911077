import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Spinner.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Button.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "flex items-center justify-start gap-2"
};
const _hoisted_2 = {
  key: 0,
  class: "text-white font-bold text-2xl md:text-3xl"
};
const _hoisted_3 = {
  key: 1,
  class: "text-white font-bold text-2xl md:text-3xl"
};
const _hoisted_4 = {
  key: 2,
  class: "text-gray-450 md:text-lg"
};
const _hoisted_5 = {
  key: 3,
  class: "text-gray-450 md:text-lg"
};
const _hoisted_6 = {
  key: 2,
  class: "flex items-center justify-between md:justify-end sm:gap-4"
};
const _hoisted_7 = { class: "text-blue-900 font-semibold" };
const _hoisted_8 = { class: "text-blue-500 font-semibold" };
import { BigNumberInBase } from "@injectivelabs/utils";
import { ZERO_IN_BASE } from "@injectivelabs/sdk-ui-ts";
import {
  UI_DEFAULT_DISPLAY_DECIMALS,
  HIDDEN_BALANCE_DISPLAY,
  UI_MINIMAL_ABBREVIATION_FLOOR
} from "@/app/utils/constants";
import { BridgeBusEvents } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Overview",
  props: {
    isLoading: Boolean,
    hideBalances: Boolean,
    balances: {
      type: Array,
      required: true
    }
  },
  emits: ["update:hide-balances"],
  setup(__props, { emit }) {
    const props = __props;
    const tokenStore = useTokenStore();
    const accountTotalBalance = computed(
      () => props.balances.reduce(
        (total, balance) => total.plus(balance.accountTotalBalance),
        ZERO_IN_BASE
      )
    );
    const accountTotalBalanceInUsd = computed(
      () => props.balances.reduce(
        (total, balance) => total.plus(balance.accountTotalBalanceInUsd),
        ZERO_IN_BASE
      )
    );
    const shouldAbbreviateTotalBalance = computed(
      () => accountTotalBalanceInUsd.value.gte(UI_MINIMAL_ABBREVIATION_FLOOR)
    );
    const accountTotalBalanceInBtc = computed(() => {
      if (!tokenStore.btcUsdPrice) {
        return ZERO_IN_BASE;
      }
      return accountTotalBalance.value.dividedBy(
        new BigNumberInBase(tokenStore.btcUsdPrice)
      );
    });
    const accountTotalBalanceInBtcToString = computed(() => {
      if (accountTotalBalanceInBtc.value.eq("0")) {
        return "0.00";
      }
      if (accountTotalBalanceInBtc.value.lte("0.0001")) {
        return "< 0.0001";
      }
      return accountTotalBalanceInBtc.value.toFormat(UI_DEFAULT_DISPLAY_DECIMALS);
    });
    const { valueToString: abbreviatedTotalBalanceToString } = useBigNumberFormatter(accountTotalBalanceInUsd, {
      decimalPlaces: shouldAbbreviateTotalBalance.value ? 0 : 2,
      abbreviationFloor: shouldAbbreviateTotalBalance.value ? UI_MINIMAL_ABBREVIATION_FLOOR : void 0
    });
    function toggleHideBalances() {
      emit("update:hide-balances", !props.hideBalances);
    }
    function handleDepositClick() {
      useEventBus(BridgeBusEvents.Deposit).emit();
    }
    function handleWithdrawClick() {
      useEventBus(BridgeBusEvents.Withdraw).emit();
    }
    return (_ctx, _cache) => {
      const _component_AppSpinner = __nuxt_component_0;
      const _component_BaseIcon = __nuxt_component_1;
      const _component_AppButton = __nuxt_component_2;
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["flex justify-between md:items-center gap-4 flex-col md:flex-row", { "mb-8": !__props.isLoading, "my-4": __props.isLoading }])
      }, [
        __props.isLoading ? (_openBlock(), _createBlock(_component_AppSpinner, {
          key: 0,
          lg: ""
        })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          !__props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_2, " $ " + _toDisplayString(_unref(abbreviatedTotalBalanceToString)) + " USD ", 1)) : (_openBlock(), _createElementBlock("span", _hoisted_3, " $ " + _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)) + " USD ", 1)),
          !__props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_4, " \u2248 " + _toDisplayString(_unref(accountTotalBalanceInBtcToString)) + " BTC ", 1)) : (_openBlock(), _createElementBlock("span", _hoisted_5, " \u2248 " + _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)) + " BTC ", 1)),
          _createElementVNode("div", { onClick: toggleHideBalances }, [
            __props.hideBalances ? (_openBlock(), _createBlock(_component_BaseIcon, {
              key: 0,
              name: "hide",
              class: "w-4 h-4 text-gray-450 hover:text-white cursor-pointer"
            })) : (_openBlock(), _createBlock(_component_BaseIcon, {
              key: 1,
              name: "show",
              class: "w-4 h-4 text-gray-450 hover:text-white cursor-pointer"
            }))
          ])
        ])),
        !__props.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_AppButton, {
            class: "bg-blue-500",
            onClick: handleDepositClick
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("account.deposit")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_AppButton, {
            class: "border border-blue-500",
            onClick: handleWithdrawClick
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("account.withdraw")), 1)
            ]),
            _: 1
          })
        ])) : _createCommentVNode("", true)
      ], 2);
    };
  }
});

import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Search.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Checkbox.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Common/InfoTooltip.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "pt-4 lg:px-4 flex flex-wrap justify-between gap-3 md:grid md:grid-cols-4 md:items-center md:gap-0" };
const _hoisted_2 = { class: "col-span-2 grid grid-cols-2" };
const _hoisted_3 = { class: "col-span-2 flex gap-2 flex-row md:items-center md:justify-end md:gap-6 md:px-0" };
const _hoisted_4 = { class: "flex items-center justify-start" };
export default /* @__PURE__ */ _defineComponent({
  __name: "Actions",
  props: {
    hideSmallBalances: Boolean,
    showMarginCurrencyOnly: Boolean,
    searchQuery: {
      type: String,
      default: ""
    }
  },
  emits: ["update:search", "update:show-margin-currency-only", "update:hide-small-balances"],
  setup(__props, { emit }) {
    const props = __props;
    const hideSmallBalancesCheck = computed({
      get: () => props.hideSmallBalances,
      set: (type) => {
        emit("update:hide-small-balances", type);
      }
    });
    const showMarginCurrencyOnlyCheck = computed({
      get: () => props.showMarginCurrencyOnly,
      set: (type) => {
        emit("update:show-margin-currency-only", type);
      }
    });
    const search = computed({
      get: () => props.searchQuery,
      set: (type) => {
        emit("update:search", type);
      }
    });
    return (_ctx, _cache) => {
      const _component_AppSearch = __nuxt_component_0;
      const _component_AppCheckbox = __nuxt_component_1;
      const _component_CommonInfoTooltip = __nuxt_component_2;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AppSearch, {
            modelValue: _unref(search),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(search) ? search.value = $event : null),
            name: "search",
            class: "col-span-1 w-full",
            "data-cy": "markets-search-input",
            "input-classes": "placeholder-white",
            dense: "",
            "transparent-bg": "",
            "show-prefix": "",
            placeholder: _ctx.$t("account.filterByAsset")
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_AppCheckbox, {
            modelValue: _unref(showMarginCurrencyOnlyCheck),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => _isRef(showMarginCurrencyOnlyCheck) ? showMarginCurrencyOnlyCheck.value = $event : null),
            "data-cy": "trade-page-filter-by-ticker-checkbox"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("account.showMarginCurrencyOnly")), 1)
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_AppCheckbox, {
            modelValue: _unref(hideSmallBalancesCheck),
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => _isRef(hideSmallBalancesCheck) ? hideSmallBalancesCheck.value = $event : null),
            "data-cy": "trade-page-filter-by-ticker-checkbox"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("account.hideSmallBalances")), 1),
                _createVNode(_component_CommonInfoTooltip, {
                  class: "ml-2",
                  tooltip: _ctx.$t("account.hideSmallBalancesTooltip")
                }, null, 8, ["tooltip"])
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ])
      ]);
    };
  }
});

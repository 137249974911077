import { default as __nuxt_component_0 } from "/opt/build/repo/components/Modals/ConvertUsdc/TokenForm/Pill.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Select/Token/Index.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, mergeProps as _mergeProps, withCtx as _withCtx, createElementBlock as _createElementBlock, Transition as _Transition, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "flex flex-col" };
const _hoisted_2 = { class: "font-semibold" };
const _hoisted_3 = { class: "flex items-center justify-center my-4 mx-auto bg-blue-500 h-8 w-8 rounded-full" };
const _hoisted_4 = { class: "font-semibold" };
import { SpotOrderSide } from "@injectivelabs/sdk-ui-ts";
import { BigNumberInBase } from "@injectivelabs/utils";
import { Modal, TradeField } from "@/types";
import { TRADE_FORM_PRICE_ROUNDING_MODE } from "@/app/utils/constants";
import { usdcTokenDenom } from "@/app/data/token";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    isLoading: Boolean,
    isBaseAmount: Boolean,
    market: {
      type: Object,
      required: true
    },
    balances: {
      type: Object,
      required: true
    },
    worstPriceWithSlippage: {
      type: Object,
      required: true
    }
  },
  emits: ["update:isBaseAmount", "update:amount"],
  setup(__props, { emit }) {
    var _a;
    const props = __props;
    const bankStore = useBankStore();
    const modalStore = useModalStore();
    const formValues = useFormValues();
    const animationCount = ref(0);
    const { takerFeeRate } = useTradeFee(computed(() => props.market));
    const baseBalance = computed(
      () => props.balances.find(
        (balance) => balance.token.denom === props.market.baseDenom
      )
    );
    const baseBalanceToBase = computed(
      () => {
        var _a2, _b;
        return new BigNumberInBase(((_a2 = baseBalance.value) == null ? void 0 : _a2.availableMargin) || 0).toWei(
          (_b = baseBalance.value) == null ? void 0 : _b.token.decimals
        );
      }
    );
    const quoteBalance = computed(
      () => props.balances.find(
        (balance) => balance.token.denom === props.market.quoteDenom
      )
    );
    const quoteBalanceToBase = computed(
      () => {
        var _a2, _b;
        return new BigNumberInBase(((_a2 = quoteBalance.value) == null ? void 0 : _a2.availableMargin) || 0).toWei(
          (_b = quoteBalance.value) == null ? void 0 : _b.token.decimals
        );
      }
    );
    const isWHSolUSDTBaseDenom = computed(
      () => props.market.baseDenom === usdcTokenDenom.USDCso
    );
    const isBuy = computed(() => orderType.value === SpotOrderSide.Buy);
    const { valueToFixed: maxBalanceToFixed } = useBigNumberFormatter(
      computed(() => {
        var _a2;
        return (_a2 = baseBalance.value) == null ? void 0 : _a2.availableMargin;
      }),
      {
        decimalPlaces: (_a = props.market) == null ? void 0 : _a.quantityDecimals
      }
    );
    const { value: orderType, setValue: setOrderType } = useStringField({
      name: TradeField.OrderType,
      initialValue: SpotOrderSide.Sell
    });
    watch(
      () => props.worstPriceWithSlippage,
      () => {
        emit("update:amount", {
          amount: props.isBaseAmount ? formValues.value[TradeField.BaseAmount] : formValues.value[TradeField.QuoteAmount],
          isBaseAmount: props.isBaseAmount
        });
      }
    );
    onMounted(() => {
      var _a2;
      if (!bankStore.hasEnoughInjForGas) {
        modalStore.openModal({ type: Modal.InsufficientInjForGas });
      }
      if ([usdcTokenDenom.USDC].includes(((_a2 = baseBalance.value) == null ? void 0 : _a2.denom.toLowerCase()) || "")) {
        handleMaxBaseAmountChange({
          amount: maxBalanceToFixed.value
        });
      }
    });
    function toggleOrderType() {
      setOrderType(isBuy.value ? SpotOrderSide.Sell : SpotOrderSide.Buy);
    }
    function handleSwap() {
      if (!isWHSolUSDTBaseDenom.value) {
        return;
      }
      animationCount.value = animationCount.value + 1;
      emit("update:isBaseAmount", !props.isBaseAmount);
      formValues.value[TradeField.BaseAmount] = "";
      formValues.value[TradeField.QuoteAmount] = "";
      toggleOrderType();
    }
    function updateAmount({
      amount,
      isBaseAmount
    }) {
      emit("update:amount", { amount, isBaseAmount });
    }
    function handleMaxBaseAmountChange({ amount }) {
      formValues.value[TradeField.BaseAmount] = amount;
      updateAmount({ amount, isBaseAmount: true });
    }
    function handleMaxQuoteAmountChange({ amount }) {
      const amountInBigNumber = new BigNumberInBase(amount);
      const feeRateToDeduct = amountInBigNumber.times(takerFeeRate.value);
      const amountDeductFee = amountInBigNumber.minus(feeRateToDeduct);
      const amountDeductFeeToFixed = amountDeductFee.toFixed(
        props.market.priceDecimals,
        TRADE_FORM_PRICE_ROUNDING_MODE
      );
      formValues.value[TradeField.BaseAmount] = amountDeductFeeToFixed;
      emit("update:amount", { amount: amountDeductFeeToFixed, isBaseAmount: false });
    }
    return (_ctx, _cache) => {
      const _component_ModalsConvertUsdcTokenFormPill = __nuxt_component_0;
      const _component_AppSelectToken = __nuxt_component_1;
      const _component_BaseIcon = __nuxt_component_2;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_Transition, {
          name: !_unref(isBuy) ? "fade-up" : "fade-down",
          mode: "out-in"
        }, {
          default: _withCtx(() => {
            var _a2;
            return [
              (_openBlock(), _createElementBlock("div", {
                key: `animation-${_unref(animationCount)}`,
                class: _normalizeClass([!_unref(isBuy) ? "order-first" : "order-last"])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex justify-between mb-2", !_unref(isBuy) ? "order-first" : "order-last"])
                }, [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(!_unref(isBuy) ? _ctx.$t("account.from") : _ctx.$t("account.to")), 1),
                  _unref(baseBalance) ? (_openBlock(), _createBlock(_component_ModalsConvertUsdcTokenFormPill, {
                    key: 0,
                    balance: _unref(baseBalance)
                  }, null, 8, ["balance"])) : _createCommentVNode("", true)
                ], 2),
                _unref(baseBalance) ? (_openBlock(), _createBlock(_component_AppSelectToken, _mergeProps({ key: 0 }, {
                  denom: _unref(baseBalance).denom,
                  amountFieldName: _unref(TradeField).BaseAmount,
                  disabled: __props.isLoading,
                  required: !_unref(isBuy),
                  hideMax: _unref(isBuy),
                  maxDecimals: (_a2 = __props.market) == null ? void 0 : _a2.quantityDecimals,
                  options: [
                    {
                      token: _unref(baseBalance).token,
                      denom: _unref(baseBalance).denom,
                      balance: _unref(baseBalanceToBase).toFixed()
                    }
                  ]
                }, {
                  "onUpdate:amount": updateAmount,
                  "onUpdate:max": handleMaxBaseAmountChange
                }), {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t(`trade.convert.${_unref(isBuy) ? "youReceive" : "youPay"}`)), 1)
                  ]),
                  _: 1
                }, 16)) : _createCommentVNode("", true)
              ], 2))
            ];
          }),
          _: 1
        }, 8, ["name"]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BaseIcon, {
            name: _unref(isWHSolUSDTBaseDenom) ? "arrow-up-down" : "arrow",
            class: _normalizeClass(["mx-auto h-5 w-5", {
              "-rotate-90": !_unref(isWHSolUSDTBaseDenom),
              "ml-2": _unref(isWHSolUSDTBaseDenom)
            }]),
            onClick: handleSwap
          }, null, 8, ["name", "class"])
        ]),
        _createVNode(_Transition, {
          name: !_unref(isBuy) ? "fade-down" : "fade-up",
          mode: "out-in"
        }, {
          default: _withCtx(() => {
            var _a2;
            return [
              (_openBlock(), _createElementBlock("div", {
                key: `animation-${_unref(animationCount)}`,
                class: _normalizeClass([!_unref(isBuy) ? "order-last" : "order-first"])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex justify-between mb-2", !_unref(isBuy) ? "order-last" : "order-first"])
                }, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(isBuy) ? _ctx.$t("account.from") : _ctx.$t("account.to")), 1),
                  _unref(quoteBalance) ? (_openBlock(), _createBlock(_component_ModalsConvertUsdcTokenFormPill, {
                    key: 0,
                    balance: _unref(quoteBalance)
                  }, null, 8, ["balance"])) : _createCommentVNode("", true)
                ], 2),
                _unref(quoteBalance) ? (_openBlock(), _createBlock(_component_AppSelectToken, _mergeProps({ key: 0 }, {
                  denom: _unref(quoteBalance).denom,
                  amountFieldName: _unref(TradeField).QuoteAmount,
                  disabled: __props.isLoading,
                  required: _unref(isBuy),
                  hideMax: !_unref(isBuy),
                  maxDecimals: (_a2 = __props.market) == null ? void 0 : _a2.quantityDecimals,
                  options: [
                    {
                      token: _unref(quoteBalance).token,
                      denom: _unref(quoteBalance).denom,
                      balance: _unref(quoteBalanceToBase).toFixed()
                    }
                  ]
                }, {
                  "onUpdate:amount": updateAmount,
                  "onUpdate:max": handleMaxQuoteAmountChange
                }), {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t(`trade.convert.${_unref(isBuy) ? "youPay" : "youReceive"}`)), 1)
                  ]),
                  _: 1
                }, 16)) : _createCommentVNode("", true)
              ], 2))
            ];
          }),
          _: 1
        }, 8, ["name"])
      ]);
    };
  }
});

"use strict";
export function useToken() {
  const tokenStore = useTokenStore();
  const fetchTokenUsdPrice = (token) => {
    const coinGeckoIdList = token.map((token2) => token2.coinGeckoId).filter((id) => id);
    return tokenStore.fetchTokenUsdPriceMap(coinGeckoIdList);
  };
  return {
    fetchTokenUsdPrice
  };
}

"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex items-end justify-start gap-2" };
export default /* @__PURE__ */ _defineComponent({
  __name: "FilterOption",
  props: {
    active: Boolean,
    option: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = _resolveComponent("CommonTokenIcon");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        __props.option && __props.option.token ? (_openBlock(), _createBlock(_component_CommonTokenIcon, {
          key: 0,
          token: __props.option.token
        }, null, 8, ["token"])) : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: _normalizeClass(["text-sm", {
            "text-white": !__props.active,
            "text-blue-500 group-hover:text-white": __props.active
          }])
        }, _toDisplayString(__props.option.display), 3)
      ]);
    };
  }
});

import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Spinner.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["data-cy"];
const _hoisted_2 = { class: "no-padding" };
const _hoisted_3 = { class: "flex flex-col py-4 pl-4" };
const _hoisted_4 = { class: "flex justify-start items-center gap-2" };
const _hoisted_5 = { class: "flex flex-col gap-1" };
const _hoisted_6 = {
  class: "text-white font-bold tracking-wide text-sm uppercase h-6 flex items-center",
  "data-cy": "wallet-balance-token-symbol-table-data"
};
const _hoisted_7 = { class: "text-gray-500 text-xs" };
const _hoisted_8 = { class: "no-padding" };
const _hoisted_9 = { class: "flex flex-col py-4" };
const _hoisted_10 = { class: "flex flex-col items-end gap-1" };
const _hoisted_11 = { "data-cy": "wallet-balance-total-table-data" };
const _hoisted_12 = {
  key: 0,
  class: "font-mono text-sm text-right"
};
const _hoisted_13 = {
  key: 1,
  class: "font-mono text-sm text-right"
};
const _hoisted_14 = { key: 2 };
const _hoisted_15 = {
  key: 1,
  class: "font-mono text-sm text-right"
};
const _hoisted_16 = {
  key: 2,
  class: "font-mono text-sm text-right"
};
const _hoisted_17 = { key: 3 };
import { Status, StatusType } from "@injectivelabs/utils";
import {
  HIDDEN_BALANCE_DISPLAY,
  UI_DEFAULT_DISPLAY_DECIMALS
} from "@/app/utils/constants";
import { BusEvents, Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Row",
  props: {
    hideBalances: Boolean,
    balance: {
      type: Object,
      required: true
    },
    usdPriceStatus: {
      type: Object,
      default: new Status(StatusType.Loading)
    }
  },
  setup(__props) {
    const props = __props;
    const modalStore = useModalStore();
    const {
      valueToString: accountTotalBalanceInUsdInString,
      valueToBigNumber: accountTotalBalanceInUsdInBigNumber
    } = useBigNumberFormatter(
      computed(() => props.balance.accountTotalBalanceInUsd),
      {
        decimalPlaces: UI_DEFAULT_DISPLAY_DECIMALS
      }
    );
    const {
      valueToString: accountTotalBalanceInString,
      valueToBigNumber: accountTotalBalanceInBigNumber
    } = useBigNumberFormatter(
      computed(() => props.balance.accountTotalBalance),
      {
        decimalPlaces: UI_DEFAULT_DISPLAY_DECIMALS
      }
    );
    function handleOpenAssetDetailsModal() {
      useEventBus(BusEvents.AssetDetailsModalPayload).emit(
        props.balance
      );
      modalStore.openModal({
        type: Modal.AssetDetails,
        preventScroll: true
      });
    }
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = __nuxt_component_0;
      const _component_AppSpinner = __nuxt_component_1;
      return _openBlock(), _createElementBlock("tr", {
        class: "border-b border-gray-700 last-of-type:border-b-transparent hover:bg-gray-700 bg-transparent overflow-hidden gap-2 transition-all",
        "data-cy": "wallet-balance-table-row-" + __props.balance.token.symbol,
        onClick: handleOpenAssetDetailsModal
      }, [
        _createElementVNode("td", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_CommonTokenIcon, {
                token: __props.balance.token
              }, null, 8, ["token"]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(__props.balance.token.symbol), 1),
                _createElementVNode("span", _hoisted_7, _toDisplayString(__props.balance.token.name), 1)
              ])
            ])
          ])
        ]),
        _createElementVNode("td", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : _unref(accountTotalBalanceInBigNumber).gt(0) ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_unref(accountTotalBalanceInString)), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_14, " \u2014 "))
              ]),
              _createElementVNode("div", null, [
                __props.usdPriceStatus.isLoading() ? (_openBlock(), _createBlock(_component_AppSpinner, {
                  key: 0,
                  md: ""
                })) : __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)) + " USD ", 1)) : _unref(accountTotalBalanceInUsdInBigNumber).gt(0) ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_unref(accountTotalBalanceInUsdInString)) + " USD ", 1)) : (_openBlock(), _createElementBlock("span", _hoisted_17, " \u2014 "))
              ])
            ])
          ])
        ])
      ], 8, _hoisted_1);
    };
  }
});

import { default as __nuxt_component_0 } from "/opt/build/repo/components/Modals/ConvertUsdc/TokenForm/Index.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Modals/ConvertUsdc/Summary.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Convert/Submit.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/HocLoading.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/App/Modal.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, isRef as _isRef, mergeProps as _mergeProps, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "mx-auto bg-gray-850 rounded-lg justify-center" };
const _hoisted_2 = {
  key: 0,
  class: "mb-6"
};
import { SpotOrderSide } from "@injectivelabs/sdk-ui-ts";
import { Status, StatusType } from "@injectivelabs/utils";
import { Modal, TradeField } from "@/types";
import { usdcTokenDenom } from "@/app/data/token";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    market: {
      type: Object,
      required: true
    },
    balances: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const spotStore = useSpotStore();
    const bankStore = useBankStore();
    const modalStore = useModalStore();
    const { t } = useLang();
    const { success } = useNotifications();
    const { $onError } = useNuxtApp();
    const { errors, resetForm, values: formValues } = useForm();
    const isBaseAmount = ref(false);
    const status = reactive(new Status(StatusType.Idle));
    const fetchStatus = reactive(new Status(StatusType.Idle));
    const submitStatus = reactive(new Status(StatusType.Idle));
    const isModalOpen = computed(() => modalStore.modals[Modal.ConvertUsdc]);
    const isBuy = computed(
      () => formValues[TradeField.OrderType] === SpotOrderSide.Buy
    );
    const amount = computed(
      () => isBuy.value ? formValues[TradeField.QuoteAmount] : formValues[TradeField.BaseAmount]
    );
    const { updateAmountFromBase, worstPrice, worstPriceWithSlippage } = useSpotPrice({
      formValues: computed(() => formValues),
      market: computed(() => props.market),
      isBaseAmount
    });
    function updateAmount({
      amount: amount2,
      isBaseAmount: isBaseAmountUpdate
    }) {
      isBaseAmount.value = isBaseAmountUpdate;
      const updatedAmount = updateAmountFromBase({
        amount: amount2,
        isBaseAmount: isBaseAmountUpdate
      });
      if (updatedAmount) {
        formValues[isBaseAmountUpdate ? TradeField.QuoteAmount : TradeField.BaseAmount] = updatedAmount;
      }
    }
    onMounted(() => {
      fetchStatus.setLoading();
      Promise.all([
        bankStore.fetchAccountPortfolio(),
        spotStore.fetchOrderbook(props.market.marketId),
        spotStore.streamOrderbookUpdate(props.market.marketId)
      ]).finally(() => fetchStatus.setIdle());
    });
    function resetFormValues() {
      const isBuyState = unref(isBuy.value);
      resetForm();
      isBaseAmount.value = !isBuyState;
      formValues[TradeField.OrderType] = isBuyState ? SpotOrderSide.Buy : SpotOrderSide.Sell;
      formValues[TradeField.BaseDenom] = props.market.baseDenom;
      formValues[TradeField.QuoteDenom] = props.market.quoteDenom;
    }
    function handleFormSubmit() {
      submitStatus.setLoading();
      spotStore.submitMarketOrder({
        isBuy: isBuy.value,
        market: props.market,
        price: worstPriceWithSlippage.value,
        quantity: formValues[TradeField.BaseAmount]
      }).then(() => {
        resetFormValues();
        bankStore.fetchAccountPortfolio();
        success({ title: t("trade.convert.convert_success") });
      }).catch($onError).finally(() => {
        submitStatus.setIdle();
        modalStore.closeModal(Modal.ConvertUsdc);
      });
    }
    function closeModal() {
      modalStore.closeModal(Modal.ConvertUsdc);
    }
    return (_ctx, _cache) => {
      const _component_ModalsConvertUsdcTokenForm = __nuxt_component_0;
      const _component_ModalsConvertUsdcSummary = __nuxt_component_1;
      const _component_PartialsConvertSubmit = __nuxt_component_2;
      const _component_AppHocLoading = __nuxt_component_3;
      const _component_AppModal = __nuxt_component_4;
      return _openBlock(), _createBlock(_component_AppModal, {
        show: _unref(isModalOpen),
        sm: "",
        "onModal:closed": closeModal
      }, {
        title: _withCtx(() => [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t("account.convertUsdc")), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_AppHocLoading, {
            status: _unref(status),
            class: "justify-center"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                __props.market.baseToken.denom.toLowerCase() === _unref(usdcTokenDenom).USDC ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t("account.whyConvert")), 1)) : _createCommentVNode("", true),
                _createVNode(_component_ModalsConvertUsdcTokenForm, _mergeProps({
                  isBaseAmount: _unref(isBaseAmount),
                  "onUpdate:isBaseAmount": _cache[0] || (_cache[0] = ($event) => _isRef(isBaseAmount) ? isBaseAmount.value = $event : null)
                }, {
                  market: __props.market,
                  balances: __props.balances,
                  worstPriceWithSlippage: _unref(worstPriceWithSlippage),
                  isLoading: _unref(fetchStatus).isLoading() || _unref(submitStatus).isLoading()
                }, { "onUpdate:amount": updateAmount }), null, 16, ["isBaseAmount"]),
                _createVNode(_component_ModalsConvertUsdcSummary, _mergeProps({ class: "mt-4" }, {
                  isBuy: _unref(isBuy),
                  market: __props.market,
                  amount: _unref(amount),
                  worstPriceWithSlippage: _unref(worstPriceWithSlippage),
                  isLoading: _unref(fetchStatus).isLoading()
                }), null, 16),
                __props.market ? (_openBlock(), _createBlock(_component_PartialsConvertSubmit, _mergeProps({
                  key: 1,
                  class: "mt-6"
                }, {
                  amount: _unref(amount),
                  errors: _unref(errors),
                  isBuy: _unref(isBuy),
                  market: __props.market,
                  executionPrice: _unref(worstPrice),
                  status: _unref(submitStatus)
                }, { "onForm:submit": handleFormSubmit }), null, 16)) : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["status"])
        ]),
        _: 1
      }, 8, ["show"]);
    };
  }
});

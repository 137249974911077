import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Account/Balances/Actions.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Account/Balances/Header.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Account/Balances/Usdc/Index.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Account/Balances/Row/Index.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Partials/Account/Balances/Mobile/Header.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Partials/Account/Balances/Mobile/Row.vue";
import { default as __nuxt_component_6 } from "/opt/build/repo/components/Common/EmptyList.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "w-full border-collapse hidden lg:table" };
const _hoisted_2 = { class: "w-full border-collapse sm:table lg:hidden" };
const _hoisted_3 = { class: "mt-2 text-xs text-gray-500" };
import { BigNumberInBase } from "@injectivelabs/utils";
import { INJ_DENOM } from "@injectivelabs/sdk-ui-ts";
import {
  QUOTE_DENOMS_GECKO_IDS,
  SMALL_BALANCE_THRESHOLD
} from "@/app/utils/constants";
import {
  AggregatedBalanceType,
  BalanceHeaderType
} from "@/types";
import { usdcTokenDenoms } from "@/app/data/token";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    hideBalances: Boolean,
    balances: {
      type: Array,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const { aggregateBalanceByDenoms } = useBalance();
    const searchQuery = ref("");
    const showMarginCurrencyOnly = ref(false);
    const hideSmallBalances = ref(false);
    const sortBy = ref(BalanceHeaderType.Value);
    const ascending = ref(false);
    const balancesWithAggregation = computed(
      () => {
        const aggregatedUsdcBalance = aggregateBalanceByDenoms({
          balances: props.balances,
          denoms: usdcTokenDenoms
        });
        const aggregatedUsdcBalanceWithType = aggregatedUsdcBalance ? [{ ...aggregatedUsdcBalance, type: AggregatedBalanceType.USDC }] : [];
        const balanceWithoutAggregationDenoms = props.balances.filter(
          (balance) => !usdcTokenDenoms.includes(balance.token.denom.toLowerCase())
        );
        return [
          ...balanceWithoutAggregationDenoms,
          ...aggregatedUsdcBalanceWithType
        ];
      }
    );
    const filteredBalances = computed(() => {
      return balancesWithAggregation.value.filter((balance) => {
        const isNotSmallBalance = !hideSmallBalances.value || new BigNumberInBase(balance.accountTotalBalance).gte(
          SMALL_BALANCE_THRESHOLD
        );
        const isMarginCurrency = !showMarginCurrencyOnly.value || QUOTE_DENOMS_GECKO_IDS.includes(balance.token.coinGeckoId);
        const tokenNameMatch = balance.token.name.toLowerCase().includes(searchQuery.value.toLowerCase());
        const tokenSymbolMatch = balance.token.symbol.toLowerCase().includes(searchQuery.value.toLowerCase());
        const isPartOfSearchFilter = searchQuery.value.trim() === "" || tokenNameMatch || tokenSymbolMatch;
        return isPartOfSearchFilter && isNotSmallBalance && isMarginCurrency;
      });
    });
    const sortedBalances = computed(() => {
      const result = [...filteredBalances.value].sort(
        (a, b) => {
          switch (sortBy.value) {
            case BalanceHeaderType.Total: {
              const totalA = new BigNumberInBase(a.accountTotalBalance);
              const totalB = new BigNumberInBase(b.accountTotalBalance);
              if (totalA.eq(totalB)) {
                return b.token.symbol.localeCompare(a.token.symbol);
              }
              return totalB.minus(totalA).toNumber();
            }
            case BalanceHeaderType.Value: {
              const totalInUsdA = new BigNumberInBase(a.accountTotalBalanceInUsd);
              const totalInUsdB = new BigNumberInBase(b.accountTotalBalanceInUsd);
              if (totalInUsdA.eq(totalInUsdB)) {
                return b.token.symbol.localeCompare(a.token.symbol);
              }
              return totalInUsdB.minus(totalInUsdA).toNumber();
            }
            case BalanceHeaderType.Available: {
              const availableA = new BigNumberInBase(a.availableMargin);
              const availableB = new BigNumberInBase(a.availableMargin);
              if (availableA.eq(availableB)) {
                return b.token.symbol.localeCompare(a.token.symbol);
              }
              return availableB.minus(availableA).toNumber();
            }
            default: {
              return b.token.symbol.localeCompare(a.token.symbol);
            }
          }
        }
      );
      const sortedBalances2 = ascending.value ? result.reverse() : result;
      const injBalance = sortedBalances2.find(({ denom }) => denom === INJ_DENOM);
      const sortedBalancesWithoutInjBalance = sortedBalances2.filter(
        ({ denom }) => denom !== INJ_DENOM
      );
      return [
        ...injBalance ? [injBalance] : [],
        ...sortedBalancesWithoutInjBalance
      ];
    });
    return (_ctx, _cache) => {
      const _component_PartialsAccountBalancesActions = __nuxt_component_0;
      const _component_PartialsAccountBalancesHeader = __nuxt_component_1;
      const _component_PartialsAccountBalancesUsdc = __nuxt_component_2;
      const _component_PartialsAccountBalancesRow = __nuxt_component_3;
      const _component_PartialsAccountBalancesMobileHeader = __nuxt_component_4;
      const _component_PartialsAccountBalancesMobileRow = __nuxt_component_5;
      const _component_CommonEmptyList = __nuxt_component_6;
      return _openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_PartialsAccountBalancesActions, {
          search: _unref(searchQuery),
          "onUpdate:search": _cache[0] || (_cache[0] = ($event) => _isRef(searchQuery) ? searchQuery.value = $event : null),
          "show-margin-currency-only": _unref(showMarginCurrencyOnly),
          "onUpdate:showMarginCurrencyOnly": _cache[1] || (_cache[1] = ($event) => _isRef(showMarginCurrencyOnly) ? showMarginCurrencyOnly.value = $event : null),
          "hide-small-balances": _unref(hideSmallBalances),
          "onUpdate:hideSmallBalances": _cache[2] || (_cache[2] = ($event) => _isRef(hideSmallBalances) ? hideSmallBalances.value = $event : null)
        }, null, 8, ["search", "show-margin-currency-only", "hide-small-balances"]),
        _createElementVNode("table", _hoisted_1, [
          _createVNode(_component_PartialsAccountBalancesHeader, _mergeProps(_ctx.$attrs, {
            "sort-by": _unref(sortBy),
            "onUpdate:sortBy": _cache[3] || (_cache[3] = ($event) => _isRef(sortBy) ? sortBy.value = $event : null),
            ascending: _unref(ascending),
            "onUpdate:ascending": _cache[4] || (_cache[4] = ($event) => _isRef(ascending) ? ascending.value = $event : null)
          }), null, 16, ["sort-by", "ascending"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortedBalances), (balance) => {
            return _openBlock(), _createElementBlock(_Fragment, {
              key: balance.token.denom
            }, [
              balance.type === _unref(AggregatedBalanceType).USDC ? (_openBlock(), _createBlock(_component_PartialsAccountBalancesUsdc, _mergeProps({ key: 0 }, _ctx.$attrs, {
                "aggregated-balance": balance,
                balances: __props.balances,
                "hide-balances": __props.hideBalances
              }), null, 16, ["aggregated-balance", "balances", "hide-balances"])) : (_openBlock(), _createBlock(_component_PartialsAccountBalancesRow, _mergeProps({ key: 1 }, _ctx.$attrs, {
                balance,
                "hide-balances": __props.hideBalances
              }), null, 16, ["balance", "hide-balances"]))
            ], 64);
          }), 128))
        ]),
        _createElementVNode("table", _hoisted_2, [
          _createVNode(_component_PartialsAccountBalancesMobileHeader, {
            "sort-by": _unref(sortBy),
            "onUpdate:sortBy": _cache[5] || (_cache[5] = ($event) => _isRef(sortBy) ? sortBy.value = $event : null),
            ascending: _unref(ascending),
            "onUpdate:ascending": _cache[6] || (_cache[6] = ($event) => _isRef(ascending) ? ascending.value = $event : null)
          }, null, 8, ["sort-by", "ascending"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortedBalances), (balance) => {
            return _openBlock(), _createBlock(_component_PartialsAccountBalancesMobileRow, _mergeProps({
              key: balance.token.denom
            }, _ctx.$attrs, {
              balance,
              "hide-balances": __props.hideBalances
            }), null, 16, ["balance", "hide-balances"]);
          }), 128))
        ]),
        _unref(sortedBalances).length === 0 ? (_openBlock(), _createBlock(_component_CommonEmptyList, {
          key: 0,
          class: "min-h-3xs bg-gray-900",
          "data-cy": "markets-no-data-table",
          message: _ctx.$t("account.balances.empty")
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("account.balances.empty")), 1)
          ]),
          _: 1
        }, 8, ["message"])) : _createCommentVNode("", true)
      ]);
    };
  }
});

import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Number/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = { class: "flex justify-between items-center gap-2" };
const _hoisted_2 = { class: "col-span-1 flex justify-start items-center gap-2" };
const _hoisted_3 = { class: "text-white font-bold tracking-wide text-sm uppercase" };
const _hoisted_4 = ["onClick"];
const _hoisted_5 = { class: "text-xs text-red-500" };
const _hoisted_6 = { class: "grid grid-cols-3 gap-4 pt-4" };
const _hoisted_7 = { class: "flex flex-col gap-1" };
const _hoisted_8 = { class: "text-xs text-gray-500" };
const _hoisted_9 = {
  key: 0,
  class: "font-mono text-sm"
};
const _hoisted_10 = { class: "flex flex-col gap-1" };
const _hoisted_11 = { class: "text-xs text-gray-500" };
const _hoisted_12 = {
  key: 0,
  class: "font-mono text-sm"
};
const _hoisted_13 = {
  key: 1,
  class: "font-mono text-sm mr-1"
};
const _hoisted_14 = {
  key: 2,
  class: "font-mono text-sm",
  "data-cy": "postion-entry-pnl"
};
const _hoisted_15 = { class: "ml-1 font-mono text-sm" };
const _hoisted_16 = {
  key: 3,
  class: "ml-1 font-mono text-sm"
};
const _hoisted_17 = {
  key: 4,
  class: "ml-1 font-mono text-sm"
};
const _hoisted_18 = {
  key: 1,
  "data-cy": "open-position-no-pnl-table-data"
};
const _hoisted_19 = { class: "font-mono text-sm" };
const _hoisted_20 = { class: "flex flex-col gap-1" };
const _hoisted_21 = { class: "text-xs text-gray-500 text-right" };
const _hoisted_22 = { class: "flex items-center justify-end" };
const _hoisted_23 = {
  key: 0,
  class: "font-mono text-sm"
};
const _hoisted_24 = ["onClick"];
const _hoisted_25 = { class: "flex flex-col gap-1" };
const _hoisted_26 = { class: "text-xs text-gray-500" };
const _hoisted_27 = { key: 0 };
const _hoisted_28 = { class: "flex flex-col gap-1" };
const _hoisted_29 = { class: "text-xs text-gray-500" };
const _hoisted_30 = {
  key: 1,
  class: "font-mono text-sm text-gray-450"
};
const _hoisted_31 = { class: "flex flex-col gap-1" };
const _hoisted_32 = { class: "text-xs text-gray-500 text-right" };
const _hoisted_33 = {
  key: 0,
  class: "font-mono text-sm text-right"
};
const _hoisted_34 = {
  key: 1,
  class: "font-mono text-sm text-right"
};
const _hoisted_35 = { class: "flex flex-col gap-1" };
const _hoisted_36 = { class: "text-xs text-gray-500" };
const _hoisted_37 = {
  key: 0,
  class: "flex items-center gap-1"
};
const _hoisted_38 = { class: "font-mono text-sm" };
const _hoisted_39 = { class: "text-sm text-gray-450 uppercase" };
const _hoisted_40 = { class: "text-sm text-gray-450 uppercase" };
const _hoisted_41 = { class: "flex flex-col gap-1" };
const _hoisted_42 = { class: "text-xs text-gray-500" };
const _hoisted_43 = {
  key: 0,
  class: "text-white text-sm font-mono"
};
const _hoisted_44 = {
  key: 1,
  class: "text-white text-sm font-mono"
};
const _hoisted_45 = {
  key: 2,
  class: "flex items-center justify-start text-white text-sm font-mono",
  "data-cy": "open-position-leverage-table-data"
};
const _hoisted_46 = /* @__PURE__ */ _createElementVNode("span", { class: "text-gray-300 text-sm font-mono" }, "\xD7", -1);
const _hoisted_47 = {
  key: 3,
  class: "text-gray-500 text-sm font-mono",
  "data-cy": "open-position-no-leverage-table-data"
};
import { TradeDirection } from "@injectivelabs/ts-types";
import { Status, StatusType } from "@injectivelabs/utils";
import { HIDDEN_BALANCE_DISPLAY } from "@/app/utils/constants";
import { BusEvents, Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "TableRowMobile",
  props: {
    hideBalances: Boolean,
    position: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const router = useRouter();
    const positionStore = usePositionStore();
    const derivativeStore = useDerivativeStore();
    const modalStore = useModalStore();
    const { success, error } = useNotifications();
    const { $onError } = useNuxtApp();
    const { t } = useLang();
    const {
      pnl,
      price,
      market,
      margin,
      quantity,
      markPrice,
      pnlToFormat,
      notionalValue,
      priceDecimals,
      percentagePnl,
      isBinaryOptions,
      quantityDecimals,
      effectiveLeverage,
      liquidationPrice
    } = useDerivativePosition(computed(() => props.position));
    const status = reactive(new Status(StatusType.Loading));
    const currentOrders = computed(() => {
      return derivativeStore.subaccountOrders;
    });
    const directionLocalized = computed(() => {
      return props.position.direction === TradeDirection.Long ? t("trade.long") : t("trade.short");
    });
    const positionCloseError = computed(() => {
      if (!market.value) {
        return;
      }
      if (notEnoughLiquidityError.value) {
        return notEnoughLiquidityError.value;
      }
      return void 0;
    });
    const notEnoughLiquidityError = computed(() => {
      if (!market.value) {
        return;
      }
      if (pnl.value.isNaN()) {
        return t("trade.no_liquidity");
      }
      return void 0;
    });
    const reduceOnlyCurrentOrders = computed(() => {
      return currentOrders.value.filter(
        (order) => order.isReduceOnly && order.marketId === props.position.marketId
      );
    });
    const hasReduceOnlyOrders = computed(() => {
      return reduceOnlyCurrentOrders.value.length > 0;
    });
    function handleAddMargin() {
      useEventBus(BusEvents.AddMarginToPosition).emit(props.position);
      modalStore.openModal({ type: Modal.AddMarginToPosition });
    }
    function handleVisitMarket() {
      if (!market.value) {
        return;
      }
      router.push({
        name: "futures-futures",
        params: {
          futures: market.value.slug
        }
      });
    }
    function handleClosePosition() {
      if (!market.value) {
        return;
      }
      if (positionCloseError.value) {
        return error({
          title: positionCloseError.value
        });
      }
      if (hasReduceOnlyOrders.value) {
        return closePositionAndReduceOnlyOrders();
      }
      return closePosition();
    }
    function closePosition() {
      if (!market.value) {
        return;
      }
      status.setLoading();
      positionStore.closePosition({
        position: props.position,
        market: market.value
      }).then(() => {
        success({
          title: t("trade.position_closed")
        });
      }).catch($onError).finally(() => {
        status.setIdle();
      });
    }
    function closePositionAndReduceOnlyOrders() {
      if (!market.value) {
        return;
      }
      status.setLoading();
      positionStore.closePositionAndReduceOnlyOrders({
        market: market.value,
        position: props.position,
        reduceOnlyOrders: reduceOnlyCurrentOrders.value
      }).then(() => {
        success({
          title: t("trade.position_closed")
        });
      }).catch($onError).finally(() => {
        status.setIdle();
      });
    }
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = __nuxt_component_0;
      const _component_AppNumber = __nuxt_component_1;
      return _unref(market) ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "border-t border-gray-600 py-4",
        onClick: handleVisitMarket
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _unref(market).baseToken ? (_openBlock(), _createBlock(_component_CommonTokenIcon, {
              key: 0,
              token: _unref(market).baseToken
            }, null, 8, ["token"])) : _createCommentVNode("", true),
            _createElementVNode("span", _hoisted_3, _toDisplayString(__props.position.ticker), 1)
          ]),
          _createElementVNode("span", {
            class: _normalizeClass(["text-sm mr-auto", {
              "text-green-500": __props.position.direction === _unref(TradeDirection).Long,
              "text-red-500": __props.position.direction === _unref(TradeDirection).Short
            }]),
            "data-cy": "open-position-trade-direction-table-data"
          }, _toDisplayString(_unref(directionLocalized)), 3),
          _createElementVNode("button", {
            class: "bg-red-500 bg-opacity-20 rounded-lg px-3 h-8 flex items-center justify-center",
            onClick: _withModifiers(handleClosePosition, ["stop"])
          }, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("account.positions.closePosition")), 1)
          ], 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("account.positions.cols.quantity")), 1),
            __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createBlock(_component_AppNumber, {
              key: 1,
              sm: "",
              flex: "",
              decimals: _unref(quantityDecimals),
              number: _unref(quantity),
              "data-cy": "open-position-quantity-table-data"
            }, null, 8, ["decimals", "number"]))
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("account.positions.cols.unrealizedPnl")), 1),
            !_unref(pnl).isNaN() ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["flex items-center", {
                "text-green-500": _unref(pnl).gte(0),
                "text-red-500": _unref(pnl).lt(0)
              }])
            }, [
              __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : _createCommentVNode("", true),
              !__props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_13, "\u2248")) : _createCommentVNode("", true),
              !__props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_unref(pnlToFormat)), 1)) : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(market).quoteToken.symbol), 1),
              __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_17, " (" + _toDisplayString(_unref(percentagePnl).toFormat(2)) + "%) ", 1))
            ], 2)) : (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t("trade.not_available_n_a")), 1)
            ]))
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("account.positions.cols.margin")), 1),
            _createElementVNode("div", _hoisted_22, [
              __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createBlock(_component_AppNumber, {
                key: 1,
                sm: "",
                flex: "",
                "data-cy": "open-position-margin-table-data",
                decimals: _unref(priceDecimals),
                number: _unref(margin)
              }, null, 8, ["decimals", "number"])),
              !_unref(isBinaryOptions) ? (_openBlock(), _createElementBlock("button", {
                key: 2,
                role: "button",
                type: "button",
                class: "border border-gray-500 text-gray-500 w-5 h-5 flex justify-center items-center hover:text-blue-500 hover:border-blue-500 ml-2",
                "data-cy": "open-position-add-margin-button",
                onClick: _withModifiers(handleAddMargin, ["stop", "prevent"])
              }, " + ", 8, _hoisted_24)) : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t("account.positions.cols.entryPrice")), 1),
            __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createBlock(_component_AppNumber, {
              key: 1,
              sm: "",
              flex: "",
              decimals: _unref(priceDecimals),
              number: _unref(price),
              "data-cy": "open-position-price-table-data"
            }, null, 8, ["decimals", "number"]))
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.$t("account.positions.cols.markPrice")), 1),
            !_unref(markPrice).isNaN() && !__props.hideBalances ? (_openBlock(), _createBlock(_component_AppNumber, {
              key: 0,
              sm: "",
              flex: "",
              decimals: _unref(priceDecimals),
              number: _unref(markPrice)
            }, null, 8, ["decimals", "number"])) : (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1))
          ]),
          _createElementVNode("div", _hoisted_31, [
            _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.$t("account.positions.cols.estLiquidationPrice")), 1),
            _unref(isBinaryOptions) ? (_openBlock(), _createElementBlock("span", _hoisted_33, " \u2014 ")) : __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createBlock(_component_AppNumber, {
              key: 2,
              sm: "",
              flex: "",
              class: "justify-end",
              decimals: _unref(priceDecimals),
              number: _unref(liquidationPrice),
              "data-cy": "open-position-liquidation-price-table-data"
            }, null, 8, ["decimals", "number"]))
          ]),
          _createElementVNode("div", _hoisted_35, [
            _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.$t("account.positions.cols.total")), 1),
            __props.hideBalances ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
              _createElementVNode("span", _hoisted_38, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1),
              _createElementVNode("span", _hoisted_39, _toDisplayString(_unref(market).quoteToken.symbol), 1)
            ])) : (_openBlock(), _createBlock(_component_AppNumber, {
              key: 1,
              sm: "",
              flex: "",
              decimals: _unref(priceDecimals),
              number: _unref(notionalValue),
              "data-cy": "open-position-total-table-data"
            }, {
              addon: _withCtx(() => [
                _createElementVNode("span", _hoisted_40, _toDisplayString(_unref(market).quoteToken.symbol), 1)
              ]),
              _: 1
            }, 8, ["decimals", "number"]))
          ]),
          _createElementVNode("div", _hoisted_41, [
            _createElementVNode("span", _hoisted_42, _toDisplayString(_ctx.$t("account.positions.cols.leverage")), 1),
            _unref(isBinaryOptions) ? (_openBlock(), _createElementBlock("span", _hoisted_43, " \u2014 ")) : __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_44, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : _unref(effectiveLeverage).gte(0) ? (_openBlock(), _createElementBlock("span", _hoisted_45, [
              _createTextVNode(_toDisplayString(_unref(effectiveLeverage).toFormat(2)) + " ", 1),
              _hoisted_46
            ])) : (_openBlock(), _createElementBlock("span", _hoisted_47, _toDisplayString(_ctx.$t("trade.not_available_n_a")), 1))
          ])
        ])
      ])) : _createCommentVNode("", true);
    };
  }
});

import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Account/Positions/Actions.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Account/Positions/TableHeader.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Account/Positions/TableRow.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Account/Positions/TableRowMobile.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Common/EmptyList.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "relative" };
const _hoisted_2 = { class: "w-full border-collapse hidden lg:table" };
const _hoisted_3 = { class: "w-full border-collapse table lg:hidden" };
const _hoisted_4 = { class: "mt-2 text-xs text-gray-500" };
import { GeneralException } from "@injectivelabs/exceptions";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    hideBalances: Boolean
  },
  setup(__props) {
    const positionStore = usePositionStore();
    const derivativeStore = useDerivativeStore();
    const { $onError } = useNuxtApp();
    const { success } = useNotifications();
    const { t } = useLang();
    const sideOptions = [
      {
        display: t("account.positions.side.short"),
        value: "short"
      },
      {
        display: t("account.positions.side.long"),
        value: "long"
      }
    ];
    const side = ref("");
    const marketDenom = ref("");
    const markets = computed(() => derivativeStore.markets);
    const positions = computed(() => positionStore.subaccountPositions);
    const marketIds = computed(() => {
      if (!marketDenom.value) {
        return [];
      }
      return markets.value.filter((m) => {
        return m.baseToken.denom === marketDenom.value || m.quoteToken.denom === marketDenom.value;
      }).map(({ marketId }) => marketId);
    });
    const filteredPositions = computed(() => {
      return positionStore.subaccountPositions.filter((position) => {
        const positionMatchedSide = !side.value || position.direction === side.value;
        const positionMatchedMarket = marketIds.value.length === 0 || marketIds.value.includes(position.marketId);
        return positionMatchedMarket && positionMatchedSide;
      });
    });
    const supportedTokens = computed(() => {
      const tokens = markets.value.reduce((tokens2, market) => {
        const baseToken = {
          balance: "",
          denom: market.baseToken.denom,
          token: market.baseToken
        };
        const quoteToken = {
          balance: "",
          denom: market.quoteDenom,
          token: market.quoteToken
        };
        return [...tokens2, baseToken, quoteToken];
      }, []);
      const uniqueTokens = [
        ...new Map(tokens.map((token) => [token.denom, token])).values()
      ];
      return uniqueTokens;
    });
    const marketOptions = computed(() => {
      return supportedTokens.value.map(({ token }) => {
        return {
          display: token.symbol,
          value: token.denom
        };
      });
    });
    const showEmpty = computed(() => {
      if (filteredPositions.value.length === 0) {
        return true;
      }
      const hasUnavailableMarkets = filteredPositions.value.every(
        (position) => markets.value.findIndex((m) => m.marketId === position.marketId) === -1
      );
      return hasUnavailableMarkets;
    });
    onMounted(() => {
    });
    onBeforeUnmount(() => {
      derivativeStore.cancelMarketsMarkPrices();
      positionStore.cancelSubaccountPositionsStream();
    });
    function handleCloseAllPositions() {
      return positions.value.length === 1 ? closePosition() : closeAllPositions();
    }
    function closeAllPositions() {
      positionStore.closeAllPosition(positions.value).then(() => {
        success({
          title: t("trade.positions_closed")
        });
      }).catch($onError);
    }
    function closePosition() {
      const [position] = positions.value;
      const market = markets.value.find((m) => m.marketId === position.marketId);
      if (!market) {
        throw new GeneralException(
          Error(
            t("trade.position_market_not_found", {
              marketId: position.marketId
            })
          )
        );
      }
      positionStore.closePosition({
        position,
        market
      }).then(() => {
        success({
          title: t("trade.positions_closed")
        });
      }).catch($onError);
    }
    return (_ctx, _cache) => {
      const _component_PartialsAccountPositionsActions = __nuxt_component_0;
      const _component_PartialsAccountPositionsTableHeader = __nuxt_component_1;
      const _component_PartialsAccountPositionsTableRow = __nuxt_component_2;
      const _component_PartialsAccountPositionsTableRowMobile = __nuxt_component_3;
      const _component_CommonEmptyList = __nuxt_component_4;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PartialsAccountPositionsActions, {
          "market-denom": _unref(marketDenom),
          "onUpdate:marketDenom": _cache[0] || (_cache[0] = ($event) => _isRef(marketDenom) ? marketDenom.value = $event : null),
          side: _unref(side),
          "onUpdate:side": _cache[1] || (_cache[1] = ($event) => _isRef(side) ? side.value = $event : null),
          "market-options": _unref(marketOptions),
          "side-options": sideOptions,
          "onPositions:close": handleCloseAllPositions
        }, null, 8, ["market-denom", "side", "market-options"]),
        _createElementVNode("table", _hoisted_2, [
          _createVNode(_component_PartialsAccountPositionsTableHeader),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredPositions), (position, i) => {
            return _openBlock(), _createBlock(_component_PartialsAccountPositionsTableRow, {
              key: `position-${i}`,
              position,
              "hide-balances": __props.hideBalances
            }, null, 8, ["position", "hide-balances"]);
          }), 128))
        ]),
        _createElementVNode("table", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredPositions), (position, i) => {
            return _openBlock(), _createBlock(_component_PartialsAccountPositionsTableRowMobile, {
              key: `position-${i}`,
              class: "",
              position,
              "hide-balances": __props.hideBalances
            }, null, 8, ["position", "hide-balances"]);
          }), 128))
        ]),
        _unref(showEmpty) ? (_openBlock(), _createBlock(_component_CommonEmptyList, {
          key: 0,
          class: "min-h-3xs bg-gray-900",
          "data-cy": "markets-no-data-table",
          message: _ctx.$t("account.positions.empty")
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("account.positions.empty")), 1)
          ]),
          _: 1
        }, 8, ["message"])) : _createCommentVNode("", true)
      ]);
    };
  }
});

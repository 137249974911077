"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { Status, StatusType } from "@injectivelabs/utils";
export default /* @__PURE__ */ _defineComponent({
  __name: "Header",
  props: {
    hideBalances: Boolean,
    showUsdcBalances: Boolean,
    aggregatedBalance: {
      type: Object,
      required: true
    },
    usdPriceStatus: {
      type: Object,
      default: new Status(StatusType.Loading)
    }
  },
  emits: ["drawer:toggle"],
  setup(__props, { emit }) {
    function handleDrawerToggle() {
      emit("drawer:toggle");
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = _resolveComponent("BaseIcon");
      const _component_PartialsAccountBalancesRowTokenSymbol = _resolveComponent("PartialsAccountBalancesRowTokenSymbol");
      const _component_PartialsAccountBalancesRowWrapper = _resolveComponent("PartialsAccountBalancesRowWrapper");
      return _openBlock(), _createBlock(_component_PartialsAccountBalancesRowWrapper, _mergeProps({
        hideBalances: __props.hideBalances,
        usdPriceStatus: __props.usdPriceStatus,
        hideActions: true,
        balance: __props.aggregatedBalance
      }, {
        class: {
          "max-h-20": !__props.showUsdcBalances,
          "max-h-screen": __props.showUsdcBalances
        }
      }), {
        tokenSymbol: _withCtx(() => [
          _createVNode(_component_PartialsAccountBalancesRowTokenSymbol, _mergeProps({ balance: __props.aggregatedBalance }, { onClick: handleDrawerToggle }), {
            symbolSuffix: _withCtx(() => [
              _createVNode(_component_BaseIcon, {
                name: "caret-down",
                class: _normalizeClass(["h-6 w-6 transition duration-300 hover:text-blue-500 transform", {
                  "rotate-180": __props.showUsdcBalances
                }])
              }, null, 8, ["class"])
            ]),
            _: 1
          }, 16)
        ]),
        _: 1
      }, 16, ["class"]);
    };
  }
});

import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Account/Positions/FilterOption.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Select/Field/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, isRef as _isRef, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "flex flex-col gap-4 md:grid md:grid-cols-4 md:items-center py-4" };
const _hoisted_2 = { class: "col-span-3 grid grid-cols-2 md:grid-cols-4 items-center gap-4 md:gap-6 w-full" };
const _hoisted_3 = { class: "text-xs text-red-500" };
const _hoisted_4 = { class: "col-span-1 items-center justify-end gap-6 hidden md:flex" };
const _hoisted_5 = { class: "text-xs text-red-500" };
export default /* @__PURE__ */ _defineComponent({
  __name: "Actions",
  props: {
    side: {
      type: String,
      default: ""
    },
    marketDenom: {
      type: String,
      default: ""
    },
    marketOptions: {
      type: Array,
      required: true
    },
    sideOptions: {
      type: Array,
      required: true
    }
  },
  emits: ["update:market-denom", "update:side", "positions:close"],
  setup(__props, { emit }) {
    const props = __props;
    const positionStore = usePositionStore();
    const showFilters = ref(true);
    const side = computed({
      get: () => props.side,
      set: (value) => {
        emit("update:side", value);
      }
    });
    const marketDenom = computed({
      get: () => props.marketDenom,
      set: (value) => {
        emit("update:market-denom", value);
      }
    });
    onMounted(() => {
      updateFilterVisibility();
    });
    function updateFilterVisibility() {
      showFilters.value = window.innerWidth > 768;
    }
    function handleCloseAllPositions() {
      emit("positions:close");
    }
    function handleToggleFilters() {
      showFilters.value = !showFilters.value;
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_PartialsAccountPositionsFilterOption = __nuxt_component_1;
      const _component_AppSelectField = __nuxt_component_2;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "flex justify-start items-center gap-2 md:hidden cursor-pointer",
            onClick: handleToggleFilters
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(["text-sm", {
                "text-gray-500": !_unref(showFilters),
                "text-white": _unref(showFilters)
              }])
            }, _toDisplayString(_ctx.$t("account.filters")), 3),
            _createVNode(_component_BaseIcon, {
              name: "filter",
              class: _normalizeClass(["w-4 h-4", {
                "text-gray-500": !_unref(showFilters),
                "text-white": _unref(showFilters)
              }])
            }, null, 8, ["class"])
          ]),
          _unref(positionStore).subaccountPositions.length ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "bg-red-500 bg-opacity-20 rounded-lg px-3 h-8 flex md:hidden items-center justify-center w-auto",
            "data-cy": "trading-account-positions-table-cancel-all-button",
            onClick: handleCloseAllPositions
          }, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("account.positions.closeAllPositions")), 1)
          ])) : _createCommentVNode("", true),
          _unref(showFilters) ? (_openBlock(), _createBlock(_component_AppSelectField, {
            key: 1,
            modelValue: _unref(marketDenom),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(marketDenom) ? marketDenom.value = $event : null),
            options: __props.marketOptions,
            placeholder: _ctx.$t("account.positions.market.label"),
            "selected-class": "border-gray-600",
            searchable: "",
            clearable: "",
            "data-cy": "universal-table-filter-by-asset-input"
          }, {
            "selected-option": _withCtx(({ option }) => [
              option ? (_openBlock(), _createBlock(_component_PartialsAccountPositionsFilterOption, {
                key: 0,
                option
              }, null, 8, ["option"])) : _createCommentVNode("", true)
            ]),
            option: _withCtx(({ option, active }) => [
              _createVNode(_component_PartialsAccountPositionsFilterOption, {
                option,
                active
              }, null, 8, ["option", "active"])
            ]),
            _: 1
          }, 8, ["modelValue", "options", "placeholder"])) : _createCommentVNode("", true),
          _unref(showFilters) ? (_openBlock(), _createBlock(_component_AppSelectField, {
            key: 2,
            modelValue: _unref(side),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => _isRef(side) ? side.value = $event : null),
            options: __props.sideOptions,
            placeholder: _ctx.$t("account.positions.side.label"),
            "selected-class": "border-gray-600",
            clearable: "",
            "data-cy": "universal-table-filter-by-side-select"
          }, null, 8, ["modelValue", "options", "placeholder"])) : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _unref(positionStore).subaccountPositions.length ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "bg-red-500 bg-opacity-20 rounded-lg px-3 h-8 flex items-center justify-center w-full md:w-auto",
            "data-cy": "trading-account-positions-table-cancel-all-button",
            onClick: handleCloseAllPositions
          }, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("account.positions.closeAllPositions")), 1)
          ])) : _createCommentVNode("", true)
        ])
      ]);
    };
  }
});

"use strict";
import { defineComponent as _defineComponent } from "vue";
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex justify-start items-center gap-2" };
const _hoisted_2 = { class: "flex justify-start gap-2 items-center" };
const _hoisted_3 = {
  class: "text-white font-bold tracking-wide text-sm h-auto flex items-center",
  "data-cy": "wallet-balance-token-symbol-table-data"
};
export default /* @__PURE__ */ _defineComponent({
  __name: "TokenSymbol",
  props: {
    balance: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = _resolveComponent("CommonTokenIcon");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "token", {}, () => [
          _createVNode(_component_CommonTokenIcon, {
            token: __props.balance.token
          }, null, 8, ["token"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(__props.balance.token.symbol), 1),
          _renderSlot(_ctx.$slots, "symbolSuffix")
        ])
      ]);
    };
  }
});

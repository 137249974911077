import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Account/Overview.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/TabMenu/Item.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/Select/Button.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Common/TabMenu/Index.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Partials/Account/Balances/Index.vue";
import { default as __nuxt_component_6 } from "/opt/build/repo/components/Partials/Account/Positions/Index.vue";
import { default as __nuxt_component_7 } from "/opt/build/repo/components/App/HocLoading.vue";
import { default as __nuxt_component_8 } from "/opt/build/repo/components/Partials/Account/Balances/AssetDetails/Index.vue";
import { default as __nuxt_component_9 } from "/opt/build/repo/components/Partials/Account/Bridge.vue";
import { default as __nuxt_component_10 } from "/opt/build/repo/components/Modals/AddMargin.vue";
import { default as __nuxt_component_11 } from "/opt/build/repo/components/Modals/ConvertUsdc/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, isRef as _isRef, createBlock as _createBlock, normalizeProps as _normalizeProps, mergeProps as _mergeProps } from "vue";
const _hoisted_1 = { class: "pt-6 sm:pb-8" };
const _hoisted_2 = { class: "text-2xl text-white font-bold mb-4" };
const _hoisted_3 = { class: "text-gray-450 text-lg mb-1" };
const _hoisted_4 = { class: "h-full-flex" };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { key: 1 };
import { Status, StatusType } from "@injectivelabs/utils";
import { BusEvents, Modal, USDCSymbol } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    balances: {
      type: Array,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const route = useRoute();
    const spotStore = useSpotStore();
    const modalStore = useModalStore();
    const tokenStore = useTokenStore();
    const positionStore = usePositionStore();
    const derivativeStore = useDerivativeStore();
    const { $onError } = useNuxtApp();
    const { fetchTokenUsdPrice } = useToken();
    const FilterList = {
      Balances: "balances",
      Positions: "positions"
    };
    const hideBalances = ref(false);
    const activeType = ref(FilterList.Balances);
    const status = reactive(new Status(StatusType.Loading));
    const usdPriceStatus = reactive(new Status(StatusType.Loading));
    const usdcConvertMarket = ref(void 0);
    onMounted(() => {
      handleViewFromRoute();
      initBalances();
      useEventBus(BusEvents.FundingRefresh).on(refreshBalances);
      useEventBus(BusEvents.ConvertUsdc).on(setUsdcConvertMarket);
    });
    onBeforeUnmount(() => {
      modalStore.closeModal(Modal.AssetDetails);
      spotStore.reset();
    });
    function setUsdcConvertMarket(token) {
      usdcConvertMarket.value = spotStore.usdcConversionModalMarkets.find(
        (market) => market.baseToken.symbol === token.symbol && market.quoteToken.symbol === USDCSymbol.WormholeEthereum
      );
    }
    function initBalances() {
      handleViewFromRoute();
      Promise.all([
        tokenStore.fetchBitcoinUsdPrice(),
        spotStore.fetchUsdcConversionMarkets(),
        derivativeStore.streamSubaccountOrders(),
        positionStore.fetchSubaccountPositions()
      ]).catch($onError).finally(() => {
        status.setIdle();
        refreshUsdTokenPrice();
      });
      Promise.all([
        derivativeStore.streamSubaccountOrders(),
        derivativeStore.streamMarketsMarkPrices(),
        positionStore.streamSubaccountPositions()
      ]);
    }
    function handleViewFromRoute() {
      const filterListValues = Object.values(FilterList);
      const tab = filterListValues.find((tab2) => tab2 === route.query.view);
      if (tab) {
        activeType.value = tab;
      }
    }
    function refreshBalances() {
      Promise.all([derivativeStore.fetchSubaccountOrders()]);
    }
    function refreshUsdTokenPrice() {
      fetchTokenUsdPrice(props.balances.map((b) => b.token)).catch($onError).finally(() => usdPriceStatus.setIdle());
    }
    function handleHideBalances(value) {
      hideBalances.value = value;
    }
    useIntervalFn(refreshUsdTokenPrice, 1e3 * 30);
    return (_ctx, _cache) => {
      const _component_PartialsAccountOverview = __nuxt_component_0;
      const _component_CommonTabMenuItem = __nuxt_component_1;
      const _component_NuxtLink = __nuxt_component_2;
      const _component_AppSelectButton = __nuxt_component_3;
      const _component_CommonTabMenu = __nuxt_component_4;
      const _component_PartialsAccountBalances = __nuxt_component_5;
      const _component_PartialsAccountPositions = __nuxt_component_6;
      const _component_AppHocLoading = __nuxt_component_7;
      const _component_PartialsAccountBalancesAssetDetails = __nuxt_component_8;
      const _component_PartialsAccountBridge = __nuxt_component_9;
      const _component_ModalsAddMargin = __nuxt_component_10;
      const _component_ModalsConvertUsdc = __nuxt_component_11;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("account.accountOverview")), 1),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("account.netWorth")), 1),
          _createVNode(_component_PartialsAccountOverview, {
            balances: __props.balances,
            "is-loading": _unref(status).isLoading() || _unref(usdPriceStatus).isLoading(),
            "hide-balances": _unref(hideBalances),
            "onUpdate:hideBalances": handleHideBalances
          }, null, 8, ["balances", "is-loading", "hide-balances"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_CommonTabMenu, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(FilterList), (filterType) => {
                  return _openBlock(), _createBlock(_component_AppSelectButton, {
                    key: `account-tabs-${filterType}`,
                    modelValue: _unref(activeType),
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(activeType) ? activeType.value = $event : null),
                    value: filterType
                  }, {
                    default: _withCtx(({ active }) => [
                      _createVNode(_component_NuxtLink, {
                        to: {
                          name: "account",
                          query: { view: filterType }
                        }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_CommonTabMenuItem, { active }, {
                            default: _withCtx(() => [
                              filterType === FilterList.Balances ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t("account.tabs.balances")), 1)) : _createCommentVNode("", true),
                              filterType === FilterList.Positions ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t("account.tabs.positions")), 1)) : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1032, ["active"])
                        ]),
                        _: 2
                      }, 1032, ["to"])
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "value"]);
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_component_AppHocLoading, { status: _unref(status) }, {
              default: _withCtx(() => [
                _unref(activeType) === FilterList.Balances ? (_openBlock(), _createBlock(_component_PartialsAccountBalances, _normalizeProps(_mergeProps({ key: 0 }, {
                  balances: __props.balances,
                  hideBalances: _unref(hideBalances),
                  usdPriceStatus: _unref(usdPriceStatus)
                })), null, 16)) : _createCommentVNode("", true),
                _unref(activeType) === FilterList.Positions ? (_openBlock(), _createBlock(_component_PartialsAccountPositions, _normalizeProps(_mergeProps({ key: 1 }, { hideBalances: _unref(hideBalances), balances: __props.balances })), null, 16)) : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["status"])
          ])
        ]),
        _unref(modalStore).modals[_unref(Modal).AssetDetails] ? (_openBlock(), _createBlock(_component_PartialsAccountBalancesAssetDetails, { key: 0 })) : _createCommentVNode("", true),
        _createVNode(_component_PartialsAccountBridge),
        _createVNode(_component_ModalsAddMargin),
        _unref(usdcConvertMarket) ? (_openBlock(), _createBlock(_component_ModalsConvertUsdc, {
          key: 1,
          balances: __props.balances,
          market: _unref(usdcConvertMarket)
        }, null, 8, ["balances", "market"])) : _createCommentVNode("", true)
      ]);
    };
  }
});

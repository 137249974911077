import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/SortableHeaderItem.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "h-14" };
const _hoisted_2 = { class: "text-gray-350 text-xs normal-case" };
const _hoisted_3 = { class: "flex items-center justify-end gap-2 h-14" };
const _hoisted_4 = { class: "text-gray-350 text-xs normal-case" };
const _hoisted_5 = /* @__PURE__ */ _createElementVNode("span", { class: "text-gray-350 text-xs" }, "/", -1);
const _hoisted_6 = { class: "text-gray-350 text-xs normal-case" };
import { BalanceHeaderType } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Header",
  props: {
    ascending: Boolean,
    sortBy: {
      type: String,
      required: true
    }
  },
  emits: ["update:sort-by", "update:ascending"],
  setup(__props, { emit }) {
    const props = __props;
    const sortByValue = computed({
      get: () => props.sortBy,
      set: (type) => {
        emit("update:sort-by", type);
      }
    });
    const ascendingValue = computed({
      get: () => props.ascending,
      set: (type) => {
        emit("update:ascending", type);
      }
    });
    return (_ctx, _cache) => {
      const _component_AppSortableHeaderItem = __nuxt_component_0;
      return _openBlock(), _createElementBlock("tr", _hoisted_1, [
        _createVNode(_component_AppSortableHeaderItem, {
          "sort-by": _unref(sortByValue),
          "onUpdate:sortBy": _cache[0] || (_cache[0] = ($event) => _isRef(sortByValue) ? sortByValue.value = $event : null),
          ascending: _unref(ascendingValue),
          "onUpdate:ascending": _cache[1] || (_cache[1] = ($event) => _isRef(ascendingValue) ? ascendingValue.value = $event : null),
          value: _unref(BalanceHeaderType).Asset
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("account.balances.cols.asset")), 1)
          ]),
          _: 1
        }, 8, ["sort-by", "ascending", "value"]),
        _createElementVNode("th", _hoisted_3, [
          _createVNode(_component_AppSortableHeaderItem, {
            "sort-by": _unref(sortByValue),
            "onUpdate:sortBy": _cache[2] || (_cache[2] = ($event) => _isRef(sortByValue) ? sortByValue.value = $event : null),
            ascending: _unref(ascendingValue),
            "onUpdate:ascending": _cache[3] || (_cache[3] = ($event) => _isRef(ascendingValue) ? ascendingValue.value = $event : null),
            class: "justify-end",
            value: _unref(BalanceHeaderType).Total
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("account.balances.cols.total")), 1)
            ]),
            _: 1
          }, 8, ["sort-by", "ascending", "value"]),
          _hoisted_5,
          _createVNode(_component_AppSortableHeaderItem, {
            "sort-by": _unref(sortByValue),
            "onUpdate:sortBy": _cache[4] || (_cache[4] = ($event) => _isRef(sortByValue) ? sortByValue.value = $event : null),
            ascending: _unref(ascendingValue),
            "onUpdate:ascending": _cache[5] || (_cache[5] = ($event) => _isRef(ascendingValue) ? ascendingValue.value = $event : null),
            class: "justify-end",
            value: _unref(BalanceHeaderType).Value
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("account.balances.cols.value", { symbol: "USD" })), 1)
            ]),
            _: 1
          }, 8, ["sort-by", "ascending", "value"])
        ])
      ]);
    };
  }
});

import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Number/Index.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = ["data-cy"];
const _hoisted_2 = { class: "pl-4" };
const _hoisted_3 = { class: "col-span-1 flex justify-start items-center gap-2" };
const _hoisted_4 = { class: "text-white font-bold tracking-wide text-xs lg:text-sm uppercase" };
const _hoisted_5 = { class: "col-span-1" };
const _hoisted_6 = { class: "col-span-1 text-right" };
const _hoisted_7 = {
  key: 0,
  class: "font-mono text-xs lg:text-sm"
};
const _hoisted_8 = { class: "col-span-1 flex flex-col text-right" };
const _hoisted_9 = { key: 0 };
const _hoisted_10 = {
  key: 2,
  class: "font-mono text-xs lg:text-sm text-gray-450"
};
const _hoisted_11 = {
  key: 3,
  class: "font-mono text-xs lg:text-sm text-gray-450"
};
const _hoisted_12 = { class: "col-span-1 text-right" };
const _hoisted_13 = {
  key: 0,
  class: "font-mono text-xs lg:text-sm"
};
const _hoisted_14 = {
  key: 1,
  class: "font-mono text-xs lg:text-sm"
};
const _hoisted_15 = { class: "col-span-1 text-right" };
const _hoisted_16 = { class: "flex items-center" };
const _hoisted_17 = {
  key: 0,
  class: "font-mono text-xs lg:text-sm"
};
const _hoisted_18 = {
  key: 1,
  class: "font-mono text-xs lg:text-sm mr-1"
};
const _hoisted_19 = {
  key: 2,
  class: "font-mono text-xs lg:text-sm"
};
const _hoisted_20 = {
  key: 3,
  class: "font-mono text-xs lg:text-sm",
  "data-cy": "postion-entry-pnl"
};
const _hoisted_21 = { class: "ml-1 font-mono text-xs lg:text-sm" };
const _hoisted_22 = {
  key: 0,
  class: "flex mt-1 font-mono text-xs lg:text-sm"
};
const _hoisted_23 = {
  key: 1,
  class: "flex mt-1 font-mono text-xs lg:text-sm"
};
const _hoisted_24 = {
  key: 1,
  "data-cy": "open-position-no-pnl-table-data"
};
const _hoisted_25 = { class: "font-mono text-xs lg:text-sm" };
const _hoisted_26 = { class: "col-span-1 flex gap-1 justify-end" };
const _hoisted_27 = {
  key: 0,
  class: "flex items-center gap-1"
};
const _hoisted_28 = { class: "font-mono text-xs lg:text-sm" };
const _hoisted_29 = { class: "text-xs lg:text-sm text-gray-450 uppercase" };
const _hoisted_30 = { class: "text-xs lg:text-sm text-gray-450 uppercase" };
const _hoisted_31 = { class: "col-span-1 flex text-right justify-end gap-1 items-center" };
const _hoisted_32 = { class: "flex items-center justify-end" };
const _hoisted_33 = {
  key: 0,
  class: "font-mono text-xs lg:text-sm"
};
const _hoisted_34 = ["onClick"];
const _hoisted_35 = { class: "col-span-1 text-right" };
const _hoisted_36 = {
  key: 0,
  class: "text-white text-xs lg:text-sm font-mono"
};
const _hoisted_37 = {
  key: 1,
  class: "text-white text-xs lg:text-sm font-mono"
};
const _hoisted_38 = {
  key: 2,
  class: "flex items-center justify-end text-white text-xs lg:text-sm font-mono",
  "data-cy": "open-position-leverage-table-data"
};
const _hoisted_39 = /* @__PURE__ */ _createElementVNode("span", { class: "text-gray-300 text-xs lg:text-sm font-mono" }, " \xD7 ", -1);
const _hoisted_40 = {
  key: 3,
  class: "text-gray-500 text-xs lg:text-sm font-mono",
  "data-cy": "open-position-no-leverage-table-data"
};
const _hoisted_41 = { class: "pr-4" };
const _hoisted_42 = { class: "col-span-1 flex items-center justify-end gap-4" };
const _hoisted_43 = ["status", "onClick"];
const _hoisted_44 = { class: "flex items-center justify-center rounded-full bg-opacity-10 w-6 h-6 hover:bg-opacity-10 bg-red-500 hover:bg-red-600 text-red-500 hover:text-red-600" };
import { TradeDirection } from "@injectivelabs/ts-types";
import { Status, StatusType } from "@injectivelabs/utils";
import { HIDDEN_BALANCE_DISPLAY } from "@/app/utils/constants";
import { BusEvents, Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "TableRow",
  props: {
    hideBalances: Boolean,
    position: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const router = useRouter();
    const derivativeStore = useDerivativeStore();
    const modalStore = useModalStore();
    const positionStore = usePositionStore();
    const { success, error } = useNotifications();
    const { $onError } = useNuxtApp();
    const { t } = useLang();
    const {
      pnl,
      price,
      market,
      margin,
      quantity,
      markPrice,
      pnlToFormat,
      priceDecimals,
      percentagePnl,
      notionalValue,
      quantityDecimals,
      isBinaryOptions,
      liquidationPrice,
      effectiveLeverage,
      markPriceToFormat
    } = useDerivativePosition(computed(() => props.position));
    const status = reactive(new Status(StatusType.Idle));
    const positionCloseError = computed(() => {
      if (!market.value) {
        return;
      }
      if (notEnoughLiquidityError.value) {
        return notEnoughLiquidityError.value;
      }
      return void 0;
    });
    const notEnoughLiquidityError = computed(() => {
      if (!market.value) {
        return;
      }
      if (pnl.value.isNaN()) {
        return t("trade.no_liquidity");
      }
      return void 0;
    });
    const reduceOnlyCurrentOrders = computed(() => {
      return derivativeStore.subaccountOrders.filter(
        (order) => order.isReduceOnly && order.marketId === props.position.marketId
      );
    });
    const hasReduceOnlyOrders = computed(() => {
      return reduceOnlyCurrentOrders.value.length > 0;
    });
    const directionLocalized = computed(() => {
      return props.position.direction === TradeDirection.Long ? t("trade.long") : t("trade.short");
    });
    function handleAddMargin() {
      useEventBus(BusEvents.AddMarginToPosition).emit(props.position);
      modalStore.openModal({ type: Modal.AddMarginToPosition });
    }
    function handleVisitMarket() {
      if (!market.value) {
        return;
      }
      router.push({
        name: "futures-futures",
        params: {
          futures: market.value.slug
        }
      });
    }
    function handleClosePosition() {
      if (!market.value) {
        return;
      }
      if (positionCloseError.value) {
        return error({
          title: positionCloseError.value
        });
      }
      if (hasReduceOnlyOrders.value) {
        return closePositionAndReduceOnlyOrders();
      }
      return closePosition();
    }
    function closePosition() {
      if (!market.value) {
        return;
      }
      status.setLoading();
      positionStore.closePosition({
        position: props.position,
        market: market.value
      }).then(() => {
        success({
          title: t("trade.position_closed")
        });
      }).catch($onError).finally(() => {
        status.setIdle();
      });
    }
    function closePositionAndReduceOnlyOrders() {
      if (!market.value) {
        return;
      }
      status.setLoading();
      positionStore.closePositionAndReduceOnlyOrders({
        market: market.value,
        position: props.position,
        reduceOnlyOrders: reduceOnlyCurrentOrders.value
      }).then(() => {
        success({
          title: t("trade.position_closed")
        });
      }).catch($onError).finally(() => {
        status.setIdle();
      });
    }
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = __nuxt_component_0;
      const _component_AppNumber = __nuxt_component_1;
      const _component_BaseIcon = __nuxt_component_2;
      return _unref(market) ? (_openBlock(), _createElementBlock("tr", {
        key: 0,
        class: "border-b border-gray-600 last-of-type:border-b-transparent hover:bg-gray-700 bg-transparent px-4 py-0 overflow-hidden h-14 gap-2 transition-all cursor-pointer",
        "data-cy": "open-position-table-row-" + __props.position.ticker,
        onClick: handleVisitMarket
      }, [
        _createElementVNode("td", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _unref(market).baseToken ? (_openBlock(), _createBlock(_component_CommonTokenIcon, {
              key: 0,
              token: _unref(market).baseToken
            }, null, 8, ["token"])) : _createCommentVNode("", true),
            _createElementVNode("span", _hoisted_4, _toDisplayString(__props.position.ticker), 1)
          ])
        ]),
        _createElementVNode("td", null, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", {
              class: _normalizeClass(["text-xs lg:text-sm", {
                "text-green-500": __props.position.direction === _unref(TradeDirection).Long,
                "text-red-500": __props.position.direction === _unref(TradeDirection).Short
              }]),
              "data-cy": "open-position-trade-direction-table-data"
            }, _toDisplayString(_unref(directionLocalized)), 3)
          ])
        ]),
        _createElementVNode("td", null, [
          _createElementVNode("div", _hoisted_6, [
            __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createBlock(_component_AppNumber, {
              key: 1,
              sm: "",
              decimals: _unref(quantityDecimals),
              number: _unref(quantity),
              "data-cy": "open-position-quantity-table-data"
            }, null, 8, ["decimals", "number"]))
          ])
        ]),
        _createElementVNode("td", null, [
          _createElementVNode("div", _hoisted_8, [
            __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createBlock(_component_AppNumber, {
              key: 1,
              sm: "",
              decimals: _unref(priceDecimals),
              number: _unref(price),
              "data-cy": "open-position-price-table-data"
            }, null, 8, ["decimals", "number"])),
            !_unref(markPrice).isNaN() && !__props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_unref(markPriceToFormat)), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1))
          ])
        ]),
        _createElementVNode("td", null, [
          _createElementVNode("div", _hoisted_12, [
            _unref(isBinaryOptions) ? (_openBlock(), _createElementBlock("span", _hoisted_13, " \u2014 ")) : __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createBlock(_component_AppNumber, {
              key: 2,
              sm: "",
              decimals: _unref(priceDecimals),
              number: _unref(liquidationPrice),
              "data-cy": "open-position-liquidation-price-table-data"
            }, null, 8, ["decimals", "number"]))
          ])
        ]),
        _createElementVNode("td", null, [
          _createElementVNode("div", _hoisted_15, [
            !_unref(pnl).isNaN() ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["flex flex-col items-end", {
                "text-green-500": _unref(pnl).gte(0),
                "text-red-500": _unref(pnl).lt(0)
              }])
            }, [
              _createElementVNode("div", _hoisted_16, [
                __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : _createCommentVNode("", true),
                !__props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_18, " \u2248 ")) : _createCommentVNode("", true),
                !__props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_unref(pnl).gte(0) ? "+" : ""), 1)) : _createCommentVNode("", true),
                !__props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_unref(pnlToFormat)), 1)) : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_21, _toDisplayString(_unref(market).quoteToken.symbol), 1)
              ]),
              __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString((_unref(percentagePnl).gte(0) ? "+" : "") + _unref(percentagePnl).toFormat(2)) + "% ", 1))
            ], 2)) : (_openBlock(), _createElementBlock("div", _hoisted_24, [
              _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t("trade.not_available_n_a")), 1)
            ]))
          ])
        ]),
        _createElementVNode("td", null, [
          _createElementVNode("div", _hoisted_26, [
            __props.hideBalances ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
              _createElementVNode("span", _hoisted_28, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1),
              _createElementVNode("span", _hoisted_29, _toDisplayString(_unref(market).quoteToken.symbol), 1)
            ])) : (_openBlock(), _createBlock(_component_AppNumber, {
              key: 1,
              sm: "",
              decimals: _unref(priceDecimals),
              number: _unref(notionalValue),
              "data-cy": "open-position-total-table-data"
            }, {
              addon: _withCtx(() => [
                _createElementVNode("span", _hoisted_30, _toDisplayString(_unref(market).quoteToken.symbol), 1)
              ]),
              _: 1
            }, 8, ["decimals", "number"]))
          ])
        ]),
        _createElementVNode("td", null, [
          _createElementVNode("div", _hoisted_31, [
            _createElementVNode("div", _hoisted_32, [
              __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createBlock(_component_AppNumber, {
                key: 1,
                sm: "",
                "data-cy": "open-position-margin-table-data",
                decimals: _unref(priceDecimals),
                number: _unref(margin)
              }, null, 8, ["decimals", "number"])),
              !_unref(isBinaryOptions) ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "cursor-pointer flex items-center justify-center rounded-full bg-opacity-10 hover:bg-opacity-30 hover:text-blue-500 text-gray-500 min-w-4 w-4 h-4 border border-gray-500 hover:border-blue-500 ml-1",
                onClick: _withModifiers(handleAddMargin, ["stop"])
              }, [
                _createVNode(_component_BaseIcon, {
                  name: "plus",
                  xs: ""
                })
              ], 8, _hoisted_34)) : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("td", null, [
          _createElementVNode("div", _hoisted_35, [
            _unref(isBinaryOptions) ? (_openBlock(), _createElementBlock("span", _hoisted_36, " \u2014 ")) : __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_37, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : _unref(effectiveLeverage).gte(0) ? (_openBlock(), _createElementBlock("span", _hoisted_38, [
              _createTextVNode(_toDisplayString(_unref(effectiveLeverage).toFormat(2)) + " ", 1),
              _hoisted_39
            ])) : (_openBlock(), _createElementBlock("span", _hoisted_40, _toDisplayString(_ctx.$t("trade.not_available_n_a")), 1))
          ])
        ]),
        _createElementVNode("td", _hoisted_41, [
          _createElementVNode("div", _hoisted_42, [
            _createElementVNode("button", {
              "data-cy": "open-position-cancel-link",
              status: _unref(status),
              class: "rounded w-6 h-6",
              onClick: _withModifiers(handleClosePosition, ["stop"])
            }, [
              _createElementVNode("div", _hoisted_44, [
                _createVNode(_component_BaseIcon, {
                  name: "close",
                  class: "h-4 w-4"
                })
              ])
            ], 8, _hoisted_43)
          ])
        ])
      ], 8, _hoisted_1)) : _createCommentVNode("", true);
    };
  }
});

"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["to"];
const _hoisted_2 = { class: "font-bold text-sm" };
const _hoisted_3 = { class: "flex justify-between items-center" };
const _hoisted_4 = {
  key: 1,
  class: "text-gray-350"
};
const _hoisted_5 = {
  key: 3,
  class: "font-mono text-sm text-gray-350"
};
import { Change } from "@injectivelabs/sdk-ui-ts";
import { BigNumberInBase } from "@injectivelabs/utils";
import { UI_DEFAULT_PRICE_DISPLAY_DECIMALS } from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "MarketCard",
  props: {
    market: {
      type: Object,
      required: true
    },
    summary: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const lastTradedPrice = computed(
      () => new BigNumberInBase(props.summary.lastPrice || props.summary.price || 0)
    );
    const change = computed(() => new BigNumberInBase(props.summary.change || 0));
    const lastPriceChange = computed(
      () => props.summary.lastPriceChange || Change.NoChange
    );
    const { valueToString: changeToFormat } = useBigNumberFormatter(change);
    const { valueToString: lastTradedPriceToFormat } = useBigNumberFormatter(
      lastTradedPrice,
      {
        decimalPlaces: props.market.priceDecimals || UI_DEFAULT_PRICE_DISPLAY_DECIMALS
      }
    );
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "rounded-lg bg-gray-750 p-4 flex flex-col gap-2",
        to: {
          name: "spot-spot",
          params: { spot: __props.market.slug }
        }
      }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(__props.market.baseToken.symbol) + "/" + _toDisplayString(__props.market.quoteToken.symbol), 1),
        _createElementVNode("div", _hoisted_3, [
          !_unref(lastTradedPrice).isNaN() ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(["font-mono text-sm", {
              "text-green-500": _unref(lastPriceChange) === _unref(Change).Increase,
              "text-gray-350": _unref(lastPriceChange) === _unref(Change).NoChange,
              "text-red-500": _unref(lastPriceChange) === _unref(Change).Decrease
            }])
          }, _toDisplayString(_unref(lastTradedPriceToFormat)), 3)) : (_openBlock(), _createElementBlock("span", _hoisted_4, "\u2014")),
          !_unref(change).isNaN() ? (_openBlock(), _createElementBlock("span", {
            key: 2,
            class: _normalizeClass(["font-mono text-sm", {
              "text-green-500": _unref(change).gt(0),
              "text-gray-350": _unref(change).eq(0),
              "text-red-500": _unref(change).lt(0)
            }])
          }, _toDisplayString(_unref(changeToFormat)) + "% ", 3)) : (_openBlock(), _createElementBlock("span", _hoisted_5, "\u2014"))
        ])
      ], 8, _hoisted_1);
    };
  }
});

import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Account/Balances/Usdc/Label.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Account/Balances/Row/TokenSymbol.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Account/Balances/Row/Wrapper.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = /* @__PURE__ */ _createElementVNode("div", { class: "pl-6" }, null, -1);
const _hoisted_2 = { class: "text-blue-500 text-sm font-medium" };
import { Status, StatusType } from "@injectivelabs/utils";
import { usdcTokenDenom } from "@/app/data/token";
import { BusEvents, Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Row",
  props: {
    hideBalances: Boolean,
    isHoldingSingleUsdcDenom: Boolean,
    balance: {
      type: Object,
      required: true
    },
    usdPriceStatus: {
      type: Object,
      default: new Status(StatusType.Loading)
    }
  },
  setup(__props) {
    const props = __props;
    const modalStore = useModalStore();
    const showConvertModalLink = computed(() => {
      return props.balance.denom.toLowerCase() === usdcTokenDenom.USDC.toLowerCase();
    });
    function handleConvert() {
      useEventBus(BusEvents.ConvertUsdc).emit(props.balance.token);
      modalStore.openModal({ type: Modal.ConvertUsdc });
    }
    return (_ctx, _cache) => {
      const _component_PartialsAccountBalancesUsdcLabel = __nuxt_component_0;
      const _component_PartialsAccountBalancesRowTokenSymbol = __nuxt_component_1;
      const _component_PartialsAccountBalancesRowWrapper = __nuxt_component_2;
      return _openBlock(), _createBlock(_component_PartialsAccountBalancesRowWrapper, _normalizeProps(_guardReactiveProps({
        balance: __props.balance,
        hideBalances: __props.hideBalances,
        usdPriceStatus: __props.usdPriceStatus
      })), _createSlots({
        tokenSymbol: _withCtx(() => [
          _createVNode(_component_PartialsAccountBalancesRowTokenSymbol, _normalizeProps(_guardReactiveProps({ balance: __props.balance })), _createSlots({
            symbolSuffix: _withCtx(() => [
              _createVNode(_component_PartialsAccountBalancesUsdcLabel, _normalizeProps(_guardReactiveProps({
                balance: __props.balance
              })), null, 16)
            ]),
            _: 2
          }, [
            !__props.isHoldingSingleUsdcDenom ? {
              name: "token",
              fn: _withCtx(() => [
                _hoisted_1
              ]),
              key: "0"
            } : void 0
          ]), 1040)
        ]),
        _: 2
      }, [
        _unref(showConvertModalLink) ? {
          name: "action",
          fn: _withCtx(() => [
            _createElementVNode("div", {
              class: "rounded flex items-center justify-center w-auto h-auto cursor-pointer",
              "data-cy": "wallet-balance-convert",
              onClick: handleConvert
            }, [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("account.convertUsdc")), 1)
            ])
          ]),
          key: "0"
        } : void 0
      ]), 1040);
    };
  }
});

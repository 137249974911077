import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Account/Balances/Row/TokenSymbol.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Spinner.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Account/Balances/Row/TradeLink.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["data-cy"];
const _hoisted_2 = { class: "pl-4 w-56" };
const _hoisted_3 = {
  class: "flex justify-end",
  "data-cy": "wallet-balance-trading-account-table-data"
};
const _hoisted_4 = {
  key: 0,
  class: "font-mono text-sm text-right"
};
const _hoisted_5 = {
  key: 1,
  class: "font-mono text-sm text-right"
};
const _hoisted_6 = { key: 2 };
const _hoisted_7 = { class: "flex justify-end" };
const _hoisted_8 = {
  key: 0,
  class: "font-mono text-sm text-right"
};
const _hoisted_9 = {
  key: 1,
  class: "font-mono text-sm text-right"
};
const _hoisted_10 = { key: 2 };
const _hoisted_11 = { class: "flex justify-end" };
const _hoisted_12 = {
  key: 0,
  class: "font-mono text-sm text-right"
};
const _hoisted_13 = {
  key: 1,
  class: "font-mono text-sm text-right"
};
const _hoisted_14 = { key: 2 };
const _hoisted_15 = {
  class: "flex justify-end",
  "data-cy": "wallet-balance-wallet-table-data"
};
const _hoisted_16 = {
  key: 0,
  class: "font-mono text-sm text-right"
};
const _hoisted_17 = {
  key: 1,
  class: "font-mono text-sm text-right"
};
const _hoisted_18 = { key: 2 };
const _hoisted_19 = { class: "flex justify-end" };
const _hoisted_20 = {
  key: 1,
  class: "font-mono text-sm text-right"
};
const _hoisted_21 = {
  key: 2,
  class: "font-mono text-sm text-right"
};
const _hoisted_22 = { key: 3 };
const _hoisted_23 = { key: 0 };
const _hoisted_24 = {
  key: 1,
  class: "pr-4"
};
const _hoisted_25 = { class: "flex items-center justify-end gap-4 col-start-2 col-span-2" };
const _hoisted_26 = { class: "text-blue-500 text-sm font-medium" };
const _hoisted_27 = { class: "text-blue-500 text-sm font-medium" };
import { BigNumberInBase, Status, StatusType } from "@injectivelabs/utils";
import {
  HIDDEN_BALANCE_DISPLAY,
  UI_DEFAULT_DISPLAY_DECIMALS
} from "@/app/utils/constants";
import { BridgeBusEvents } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Wrapper",
  props: {
    hideActions: Boolean,
    hideBalances: Boolean,
    balance: {
      type: Object,
      required: true
    },
    usdPriceStatus: {
      type: Object,
      default: new Status(StatusType.Loading)
    }
  },
  setup(__props) {
    const props = __props;
    const {
      valueToString: accountTotalBalanceInUsdInString,
      valueToBigNumber: accountTotalBalanceInUsdInBigNumber
    } = useBigNumberFormatter(
      computed(() => props.balance.accountTotalBalanceInUsd),
      {
        decimalPlaces: UI_DEFAULT_DISPLAY_DECIMALS
      }
    );
    const {
      valueToString: accountTotalBalanceInString,
      valueToBigNumber: accountTotalBalanceInBigNumber
    } = useBigNumberFormatter(
      computed(() => props.balance.accountTotalBalance),
      {
        decimalPlaces: UI_DEFAULT_DISPLAY_DECIMALS
      }
    );
    const {
      valueToString: availableMarginInString,
      valueToBigNumber: availableMarginInBigNumber
    } = useBigNumberFormatter(
      computed(() => new BigNumberInBase(props.balance.availableMargin)),
      {
        decimalPlaces: UI_DEFAULT_DISPLAY_DECIMALS
      }
    );
    const {
      valueToString: inOrderBalanceInString,
      valueToBigNumber: inOrderBalanceInBigNumber
    } = useBigNumberFormatter(
      computed(() => props.balance.inOrderBalance),
      {
        decimalPlaces: UI_DEFAULT_DISPLAY_DECIMALS
      }
    );
    const {
      valueToString: unrealizedPnlInString,
      valueToBigNumber: unrealizedPnlInBigNumber
    } = useBigNumberFormatter(
      computed(() => props.balance.unrealizedPnl),
      {
        decimalPlaces: UI_DEFAULT_DISPLAY_DECIMALS
      }
    );
    function handleDepositClick() {
      useEventBus(BridgeBusEvents.Deposit).emit(
        props.balance.token
      );
    }
    function handleWithdrawClick() {
      useEventBus(BridgeBusEvents.Withdraw).emit(
        props.balance.token
      );
    }
    return (_ctx, _cache) => {
      const _component_PartialsAccountBalancesRowTokenSymbol = __nuxt_component_0;
      const _component_AppSpinner = __nuxt_component_1;
      const _component_PartialsAccountBalancesRowTradeLink = __nuxt_component_2;
      return _openBlock(), _createElementBlock("tr", {
        class: "border-b border-gray-700 hover:bg-gray-700 bg-transparent px-4 py-0 overflow-hidden h-14 gap-2 transition-all max-h-20",
        "data-cy": "wallet-balance-table-row-" + __props.balance.token.symbol
      }, [
        _createElementVNode("td", _hoisted_2, [
          _renderSlot(_ctx.$slots, "tokenSymbol", {}, () => [
            _createVNode(_component_PartialsAccountBalancesRowTokenSymbol, _normalizeProps(_guardReactiveProps({ balance: __props.balance })), null, 16)
          ])
        ]),
        _createElementVNode("td", null, [
          _createElementVNode("div", _hoisted_3, [
            __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : _unref(availableMarginInBigNumber).gt(0) ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref(availableMarginInString)), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_6, " \u2014 "))
          ])
        ]),
        _createElementVNode("td", null, [
          _createElementVNode("div", _hoisted_7, [
            __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : _unref(inOrderBalanceInBigNumber).gt(0) ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_unref(inOrderBalanceInString)), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_10, " \u2014 "))
          ])
        ]),
        _createElementVNode("td", null, [
          _createElementVNode("div", _hoisted_11, [
            __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : _unref(unrealizedPnlInBigNumber).gt(0) ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_unref(unrealizedPnlInString)), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_14, " \u2014 "))
          ])
        ]),
        _createElementVNode("td", null, [
          _createElementVNode("div", _hoisted_15, [
            __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : _unref(accountTotalBalanceInBigNumber).gt(0) ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_unref(accountTotalBalanceInString)), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_18, " \u2014 "))
          ])
        ]),
        _createElementVNode("td", null, [
          _createElementVNode("div", _hoisted_19, [
            __props.usdPriceStatus.isLoading() ? (_openBlock(), _createBlock(_component_AppSpinner, {
              key: 0,
              md: ""
            })) : __props.hideBalances ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)) + " USD ", 1)) : _unref(accountTotalBalanceInUsdInBigNumber).gt(0) ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_unref(accountTotalBalanceInUsdInString)) + " USD ", 1)) : (_openBlock(), _createElementBlock("span", _hoisted_22, " \u2014 "))
          ])
        ]),
        __props.hideActions ? (_openBlock(), _createElementBlock("td", _hoisted_23)) : (_openBlock(), _createElementBlock("td", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _renderSlot(_ctx.$slots, "action", {}, () => [
              _createVNode(_component_PartialsAccountBalancesRowTradeLink, { balance: __props.balance }, null, 8, ["balance"]),
              _createElementVNode("div", {
                class: "rounded flex items-center justify-center w-auto h-auto cursor-pointer",
                "data-cy": "wallet-balance-deposit-link",
                onClick: handleDepositClick
              }, [
                _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t("account.deposit")), 1)
              ]),
              _createElementVNode("div", {
                class: "rounded flex items-center justify-center w-auto h-auto cursor-pointer",
                "data-cy": "wallet-balance-withdraw-link",
                onClick: handleWithdrawClick
              }, [
                _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.$t("account.withdraw")), 1)
              ])
            ])
          ])
        ]))
      ], 8, _hoisted_1);
    };
  }
});
